import {Component, Inject, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {APP_CONFIG} from "@creditsnap/app-config";

@Component({
  selector: 'creditsnap-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  progress = 0;
  routes: any = {
    'about': false,
    'trade': false,
    'offer': false,
    // 'add-on': false,
    'review': false,
    'provision': false,
    'thank-you': false
  };
  currentRoute: string;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private router: Router,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        this.currentRoute = url[0] || 'about';
        if (url[0] === 'manage-offer') {
          this.currentRoute = 'offer';
        }
        if (this.routes.hasOwnProperty(this.currentRoute)) {
          let keys = Object.keys(this.routes);
          for (let i = 0; i < keys.length; i++) {
            if (i > 0) {
              this.progress = (i / keys.length) * 100 + 4;
            }
            if (keys[i] === this.currentRoute) {
              break;
            }
            this.routes[keys[i]] = true;
          }
        }
      }
    })
  }

  ngOnInit(): void {
  }

}
