import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {CollateralModel, CSPostResponseModel} from '@creditsnap/data-models';
import {APP_ENV_CONFIG, APP_CONFIG} from "../index";

@Injectable({
  providedIn: 'root'
})
export class CollateralService {
  API_COLLATERAL_URL = '';
  generateOfferForCollateralChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  generateOfferForCollateralChanged$ = this.generateOfferForCollateralChanged.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    @Inject(APP_ENV_CONFIG) private environment: any,
  ) {
    this.API_COLLATERAL_URL = environment.apiUrl + '/collateral'
  }

  // This service can act as adding new collateral or updating the existing.
  // If collateral entity has collateralId then backend updates the existing collateral entity
  addCollateral(collateral: CollateralModel, _applicationId: number): Observable<CSPostResponseModel> {
    return this.http.post<CSPostResponseModel>(this.API_COLLATERAL_URL + `/${_applicationId}`, collateral);
  }

  // call for update VIN at finance page
  updateCollateral(collateral: CollateralModel, _applicationId: number): Observable<CSPostResponseModel> {
    return this.http.put<CSPostResponseModel>(this.API_COLLATERAL_URL + `/${_applicationId}`, collateral);
  }

  // Each application has single collateral, so read data attribute for the collateral entity
  getCollateral(_applicationId: number): Observable<CollateralModel> {
    return this.http.get<CollateralModel>(this.API_COLLATERAL_URL + `/${_applicationId}`);
  }

  generateCollateralLTV(_applicationId: number): Observable<CSPostResponseModel> {
    return this.http.put<CSPostResponseModel>(this.API_COLLATERAL_URL + `/valuation/${_applicationId}`, {});
  }

  getCollateralYears(): Observable<any> {
    return this.http.get<any>(this.API_COLLATERAL_URL + `/lookup/years`);
  }

  getCollateralMakesByYear(_yearCode: number): Observable<any> {
    return this.http.get<any>(this.API_COLLATERAL_URL + `/lookup/${_yearCode}/make`);
  }

  getCollateralSeriesByMakesAndYear(_yearCode: number, _makeCode: number): Observable<any> {
    return this.http.get<any>(this.API_COLLATERAL_URL + `/lookup/${_yearCode}/${_makeCode}/series`);
  }

  getCollateralBodiesBySeriesAndMakesAndYear(_yearCode: number, _makeCode: number, _seriesCode: number): Observable<any> {
    return this.http.get<any>(this.API_COLLATERAL_URL + `/lookup/${_yearCode}/${_makeCode}/${_seriesCode}/bodies`);
  }

  upsertTradeLines(collateral: CollateralModel, _applicationId: number): Observable<CSPostResponseModel> {
    return this.http.post<CSPostResponseModel>(`${this.API_COLLATERAL_URL}/tradelines/${_applicationId}`, collateral);
  }

  getCollateralLoanAmount(productType: string, _applicationId: number, defaultLoanAmount: number): Observable<CSPostResponseModel> {
    return this.http.post<CSPostResponseModel>(`${this.API_COLLATERAL_URL}/tradelines/${_applicationId}/product/${productType}/default/${defaultLoanAmount}`, {});
  }

  addCollateralAddress(addressVO: any) {
    return this.http.post<CSPostResponseModel>(`${this.environment.apiUrl}/applicant/address/${addressVO.applicantId}`, addressVO);
  }

  getVinDetails(vin: any) {
    return this.http.get<any>(this.API_COLLATERAL_URL + `/lookup/vin/${vin}`);
  }

  addTradeVehicle(_applicationId: number, collateral: any) {
    return this.http.post<CSPostResponseModel>(`${this.environment.apiUrl}/collateral/tradeinvehicle/${_applicationId}`, collateral);
  }

  getTradeVehicle(_applicationId: number) {
    return this.http.get<CollateralModel>(`${this.environment.apiUrl}/collateral/tradeinvehicle/${_applicationId}`);
  }

  getVehicleFromLicencePlate(licencePlateNumber: string, state: string) {
    return this.http.get<any>(  `${this.API_COLLATERAL_URL}/lookup/plate/${licencePlateNumber}/state/${state}`);
  }
}
