import { Pipe, PipeTransform } from '@angular/core';
import {Country} from "@creditsnap/data-models";


@Pipe({
	name: 'searchCountry',
})
export class SearchCountryPipe implements PipeTransform {
	// country | searchCountry:'searchCriteria'
	transform(country: Country, searchCriteria?: string): boolean {
		if (!searchCriteria || searchCriteria === '') {
			return true;
		}

		return `${country.name}+${country.dialCode}`
			.toLowerCase()
			.includes(searchCriteria.toLowerCase());
	}
}
