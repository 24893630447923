import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) {
          return value;
        }
      
        let newVal = value.replace(/\D/g, '');
      
        let countryCode = '';
        if (newVal.length > 10 && newVal.startsWith('1')) {
          countryCode = '+1 ';
          newVal = newVal.substring(1); 
        }
      
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
      
        return countryCode + newVal; 
      }
      

}