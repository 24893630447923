import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicantModel} from '@creditsnap/data-models';
import {ApplicantService, OfferService} from '@creditsnap/app-config';

@Component({
	selector: 'creditsnap-ask-ssn',
	templateUrl: './ask-ssn.component.html',
	styleUrls: ['./ask-ssn.component.scss']
})
export class AskSsnComponent implements OnInit {
	barButtonOptions: any = {
		active: false,
		text: 'Continue',
		buttonColor: 'primary',
		barColor: '#C1D72E',
		raised: true,
		stroked: false,
		mode: 'indeterminate',
		value: 0,
		disabled: false
	};
	applicant: ApplicantModel;
	errorMessage: string = null;

	constructor(
		public dialogRef: MatDialogRef<AskSsnComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private offerService: OfferService,
		private applicantService: ApplicantService
	) {
		this.applicant = data;
	}

	ngOnInit() {
	}

	onSubmit() {
		this.barButtonOptions.active = true;
		const applicant = this.applicant;
		applicant.ssn = this.applicant.ssn.toString().replace(/([\s,.€()_-])+/g, '');
		this.applicantService.updateApplicant(this.applicant.applicationId, applicant).subscribe(res => {
			this.orderCreditBureauReport(applicant);
		}, err => {
			this.barButtonOptions.active = false;
			this.errorMessage = err.message;
		});
	}

	orderCreditBureauReport(applicant: any) {
		const passThrewValue = {
			passThrewValue: {
				ssn: applicant.ssn
			}
		};
		this.offerService.orderCreditBureauReport(this.applicant.applicationId, this.applicant.applicantId, true, passThrewValue)
			.subscribe(res => {
				this.dialogRef.close(res);
			}, err => {
				this.barButtonOptions.active = false;
				this.errorMessage = err.message;
			});
	}

}
