export class AchModel {
	achPaymentDetailsId: number;
	applicationId: number;
	institutionCode: string;
	amount: string;
	accountNumber: string;
	routingNumber: string;
	accountType: string;
	recordStatus: string;
	bankName: string;
}
