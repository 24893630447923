<div class="footer mat-elevation-z4" [style.background-color]="appConfig?.footerConfig?.footerTop?.BGColor"
	 [style.color]="appConfig?.footerConfig?.footerTop?.FontColor">
  <div class="custom-container">
    <div class="row">
      <div class="col-12">
        <div class="footer-content text-center">
          <div class="footer-title">
            <div class="img-wrapper">
              <img src="https://s3.amazonaws.com/creditsnapimages/{{env.institutionId}}/EqualHousingLender.png" alt="">
            </div>
            <div class="img-wrapper">
              <img src="/assets/images/ncua.png" alt="footer icon" class="img-fluid footer-img">
            </div>
            <p class="mb-0" [style.color]="appConfig?.footerConfig?.footerTop?.FontColor">
              Federally insured by NCUA. Equal Housing Lender.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright" [style.background-color]="appConfig?.footerConfig?.footerBottom?.BGColor"
	 [style.color]="appConfig?.footerConfig?.footerBottom?.FontColor">
  <div class="custom-container">
    <div class="row">
      <div class="col-12">
        <div class="copyright-details">
          <p class="mb-0">&copy; {{currentYear}} {{appConfig?.footerConfig?.footerBottom?.copyRight}}</p>
          <a href="tel: {{appConfig?.footerConfig?.footerBottom?.phone}}" class="d-flex" id="contactNumberLink">
            <span class='material-icons me-1'>phone</span>
            {{appConfig?.footerConfig?.footerBottom?.phone}}</a>
        </div>
      </div>
    </div>
  </div>

</div>

