import {ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {APP_CONFIG, APP_ENV_CONFIG, SharedKeyDataService} from '@creditsnap/app-config';
import {KeyIdentifierModel} from '@creditsnap/data-models';
import {Subscription} from 'rxjs';

@Component({
    selector: 'creditsnap-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
    hideButton = false;
    keyIdentifier: KeyIdentifierModel;
    partnerLogoUrl: string;
    subscription: Subscription;
    merchantId: any;
    merchantLogo: any;

    constructor(
        @Inject(APP_CONFIG) public appConfig: any,
        @Inject(APP_ENV_CONFIG) public environment: any,
        private sharedKeyDataService: SharedKeyDataService,
        private cdr: ChangeDetectorRef
    ) {
        this.sharedKeyDataService.keyIdentifier$.subscribe((data) => {
            console.log(data, 'data');
            this.keyIdentifier = data;
            if (this.keyIdentifier.merchantId) {
                this.merchantId = this.keyIdentifier.merchantId;
                this.merchantLogo = environment.s3Images + 'images/' + environment.institutionId + '/' + this.merchantId + '_logo.png' || environment.s3Images + environment.institutionId + '/' + 'logo.png';
            }
            if (this.keyIdentifier.referralCode) {
                this.partnerLogoUrl = `${environment.s3Images}${environment.institutionId}/${this.keyIdentifier.referralCode}_logo.png`;
            }
        });

    }

    buttonHide(type: any) {
        this.hideButton = type === 'showClass';
    }

    onPartnerLogoError(event) {
        this.partnerLogoUrl = '';
        this.cdr.detectChanges();
    }

    onMerchantLogoError(event) {
        // set default to institution logo
        this.merchantLogo = this.environment.s3Images + this.environment.institutionId + '/' + 'logo.png';
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
