<div class="container-fluid">
	<div class="row">
		<mat-stepper [linear]="true" #productSelectionStepper [class.stepper-navigation-hidden]="navigationHidden">
			<mat-step>
				<!-- Step 1 content -->
				<div class="main-heading">
					<h1 [innerHTML]="appTextConfig.productSelection.title"></h1>
				</div>
				<div class="text-center">
					<img fetchpriority="high" class="img-fluid" width="150" src="{{environment.s3Images}}{{environment.institutionId}}/image-id.gif" alt="Open an Account graphic">
					<div class="check-list" [innerHTML]="appTextConfig.productSelection.description"></div>

					<button mat-button mat-raised-button color="primary" matStepperNext >
						{{ appTextConfig?.productSelection?.getStartedButtonLabel || 'Get Started' }}
					</button>
				</div>
			</mat-step>

			<!-- Step 2 : Product Category Selection -->
			<mat-step>
				<creditsnap-radio class="product-category" [radioArray]="productTypeListRadioObject"
								  [radioGroupModel]="selectedProductCategory?.value"
								  (selectionChange)="changeProductCategory($event)">
				</creditsnap-radio>

				<div>
					<button mat-button mat-raised-button color="primary"
							[disabled]="!selectedProductCategory?.value"
							(click)="goToProductSelection()">Next</button>
				</div>
			</mat-step>

			<!-- Step 3 : Product Selection -->
			<mat-step *ngIf="productListRadioObject?.items?.length > 1">
				<creditsnap-radio class="product-category" [radioArray]="productListRadioObject"
								  [radioGroupModel]="selectedProductData?.value"
								  (selectionChange)="changeProductSelection($event)">
				</creditsnap-radio>

				<div>
					<button mat-button mat-stroked-button matStepperPrevious>Back</button>
					<button mat-button mat-raised-button color="primary" [disabled]="!selectedProductData?.value"
							(click)="submitProductSelection()">Next</button>
				</div>
			</mat-step>

			<!-- Step 4 : Product Sub Product Selection -->
			<mat-step *ngIf="subProductListRadioObject?.items?.length > 1 && !skipSubProductStep">
				<creditsnap-radio class="product-category" [radioArray]="subProductListRadioObject"
								  [radioGroupModel]="selectedSubProductData?.value"
								  (selectionChange)="changeSubProductSelection($event)">
				</creditsnap-radio>

				<div>
					<button mat-button mat-stroked-button matStepperPrevious>Back</button>
					<button mat-button mat-raised-button color="primary" [disabled]="!selectedSubProductData?.value"
							(click)="submitProductSelection()" >Next</button>
				</div>
			</mat-step>
		</mat-stepper>
	</div>
</div>
