import {Component, Inject, OnInit} from '@angular/core';
import {APP_CONFIG, APP_ENV_CONFIG} from "@creditsnap/app-config";

@Component({
  selector: 'creditsnap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
	@Inject(APP_ENV_CONFIG) public env: any,
  ) {
  }

  ngOnInit(): void {
  }

}
