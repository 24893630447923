import {InjectionToken} from "@angular/core";
import {DefaultUrlSerializer, UrlTree} from "@angular/router";

// Config
export * from './config/common.config'
export * from './config/common-text.config'

// Service
export * from './services/http-intercepter.service'
export * from './services/sharedkey-data.service'
export * from './services/authentication.service'
export * from './services/util.service'
export * from './services/application.service'
export * from './services/applicant.service'
export * from './services/offer.service'
export * from './services/app-config.service'
export * from './services/collateral.service'
export * from './services/offer.util.service'
export * from './services/loader.service'
export {
	calcDTILoanAmount,
	calculateAPR,
	calcMonthlyPayment,
	monthsBetweenDates,
	calcEMI,
	OfferWithLtvUtilService,
	copyObject
} from './services/offer-with-ltv.util.service'
export * from "./services/finance.service"
export * from "./services/underwriting.service"
export * from "./services/script-loader.service"
export * from "./services/message.service"
export * from "./services/otp.service"
export * from "./services/provision.service"
export * from "./services/socket.service"

export const APP_ENV_CONFIG = new InjectionToken("Application Environment Config");
export const APP_TEXT_CONFIG = new InjectionToken("Application Text Config");
export const APP_CONFIG = new InjectionToken("Application Custom Config");


export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	override parse(url: string): UrlTree {
		// Optional Step: Do some stuff with the url if needed.

		// If you lower it in the optional step
		// you don't need to use "toLowerCase"
		// when you pass it down to the next function

		const urlPaths = url.split("?");
		let urlLowerCase = `${urlPaths[0].toLowerCase()}`;
		urlLowerCase += urlPaths[1] ? `?${urlPaths[1]}` : '';
		return super.parse(urlLowerCase);
	}
}
