import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'replaceString', pure: false})

export class ReplaceStringPipe implements PipeTransform {
    transform(value: string, args?: any): string {
        if (!value) { return value; }
		// console.log(args);
        // For each argument
        for (const key of Object.keys(args)) {
            if (args.hasOwnProperty(key) && value) {
                value = String(value).replaceAll('{{' + key + '}}', args[key]);
            }
        }

        return value;
    }
}
