<div class="main-header">
  <div class="header-top" [ngClass]="{'show': hideButton, '': !hideButton}"
       [style.background-color]="appConfig?.headerConfig?.BGColor"
       [style.color]="appConfig?.headerConfig?.FontColor">
    <div class="custom-container">
      <div class="row">
        <div class="col-12">
          <div class="top-header-content" id="navbarSupportedContent">
            <ul class="mb-0">
              <button class="close d-md-none" (click)="buttonHide('hideCalss')">
                x
              </button>
              <li *ngIf="appConfig.phoneNumber">
                <a id="phoneNumLink" href="tel: {{appConfig.phoneNumber}}" title="Call: {{appConfig.phoneNumber}}"
                   [style.color]="appConfig?.headerConfig?.FontColor">
                  <span class='material-icons me-1'>phone</span>
                  {{appConfig.phoneNumber}}
                </a>
              </li>
              <li *ngIf="appConfig.phoneNumber2">
                <a id="phoneNum2Link" href="tel: {{appConfig.phoneNumber2}}"
                   [style.color]="appConfig?.headerConfig?.FontColor" title="Call: {{appConfig.phoneNumber2}}">
                  <span class='material-icons me-1'>phone</span>
                  {{appConfig.phoneNumber2}}
                </a>
              </li>
              <li>
                <a [style.color]="appConfig?.headerConfig?.FontColor" id="myApplicationLink" routerLink="/myapp">My
                  Application</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="header" [style.background-color]="appConfig?.headerConfig?.LogoBackgroundColor"
       [style.color]="appConfig?.headerConfig?.FontColor">
    <div class="custom-container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between">
            <div class="img-wrapper" *ngIf="!merchantId">
              <a id="logoUrl" [href]="appConfig?.logoUrl || environment.websiteUrl" target="_blank">
                <img alt="Header Logo" class="img-fluid brand"
                     src="{{appConfig.logoName || environment.institutionId}}">
              </a>
            </div>
            <div class="img-wrapper" *ngIf="merchantId && merchantId.length > 0">
              <a id="logoUrl_merchant" [href]="appConfig?.logoUrl || environment.websiteUrl" target="_blank">
                <img alt="Header Logo" class="img-fluid brand" [src]="merchantLogo"
                     (error)="onMerchantLogoError($event)">
              </a>
            </div>
            <div class="img-wrapper d-md-block d-none" *ngIf="partnerLogoUrl">
              <!--							<a [href]="appConfig?.logoUrl || environment.websiteUrl" target="_blank">-->
              <img [alt]="keyIdentifier.referralCode" class="img-fluid brand" [src]="partnerLogoUrl"
                   (error)="onPartnerLogoError($event)">
              <!--							</a>-->
            </div>
            <button class="navbar-toggler navbar-light d-md-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation" (click)="buttonHide('showClass')">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
