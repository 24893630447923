import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
	selector: '[input-num]',
})
export class InputNumberDirective {

	constructor(public elRef: NgControl) {
	}

	@HostListener('ngModelChange', ['$event'])
	onModelChange(event: any) {
		this.onInputChange(event);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event: any) {
		this.onInputChange(event.target.value);
	}

	onInputChange(event: any) {
		if (!event) {
			return;
		}

		try {
			let newVal = event.toString().replace(/\D/g, '');
			if (this.elRef.name === 'zipCode') {
				if (newVal.length === 0) {
					newVal = '';
				} else if (newVal.length <= 5) {
					newVal = newVal.replace(/^(\d{0,5})/, '$1');
				} else {
					newVal = newVal.substring(0, 5);
				}
			}
			this.elRef.valueAccessor.writeValue(+newVal);
		} catch (e) {
			console.log(e)
		}
	}
}
