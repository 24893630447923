import {OfferModel} from './offer.model';
import {CollateralModel} from './collateral.model';

export class LoanAmountVariationsModel {
	payOffAmount = 9999999;
	customizedLoanAmount = 9999999;
	offerCustomizedFromCards = false;
	isVehicleValuationCompleted = false;
	hasCustomerOptedOffer = false;
	previouslySelectedOffer: OfferModel;
	collateral: CollateralModel;
	loanPurposeConfig: any;
	selectedTerm = 0;
}
