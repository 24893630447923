import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicantModel, CurrencyPipe, IncomeModel, ProgressButtonOptions} from '@creditsnap/data-models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { APP_CONFIG, APP_TEXT_CONFIG, ApplicantService } from "@creditsnap/app-config";
import * as moment from "moment";

@Component({
	selector: 'creditsnap-additional-income',
	templateUrl: './additional-income.component.html',
	providers: [CurrencyPipe]
})
export class AdditionalIncomeComponent {
	barButtonOptions: ProgressButtonOptions = {
		active: false,
		text: 'Save',
		buttonColor: 'primary',
		barColor: '#C1D72E',
		raised: true,
		stroked: false,
		mode: 'indeterminate',
		value: 0,
		disabled: false
	};
	incomeForm: FormGroup;
	applicant: ApplicantModel;
	errorMessage: string = null;
	incomeTypeList: any[] = this.appConfig.additionalIncome.types;

	constructor(
		public dialogRef: MatDialogRef<AdditionalIncomeComponent>,
		private fb: FormBuilder,
		private applicantService: ApplicantService,
		private currencyPipe: CurrencyPipe,
		@Inject(APP_CONFIG) public appConfig: any,
		@Inject(APP_TEXT_CONFIG) public appTextConfig: any,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.applicant = data.applicant || new ApplicantModel();
		// this.incomeForm = this.fb.group({
		//     incomeAdd: this.fb.array([])
		// });
		const incomeAdd = [];
		const additionalIncomes: IncomeModel[] = this.applicant.additionalIncomes || [];

		console.log(additionalIncomes);
		if (additionalIncomes.length > 0) {
			additionalIncomes.map(income => {
				if (!income.annualAmount) {
					income.annualAmount = '0';
				}
				income.annualAmount = this.currencyPipe.transform(income.annualAmount.replace(/[^0-9]*/g, ''));
				income.startDateFull = income.startDate ? new Date(income.startDate) : null;
				incomeAdd.push(this.fb.group({
					additionalIncomeId: [income.additionalIncomeId],
					amount: [income.amount],
					annualAmount: [income.annualAmount, Validators.required],
					incomeType: [income.incomeType, Validators.required],
					schedule: [income.schedule],
					startDate: [income.startDate],
					startDateFull: [income.startDateFull, this.appConfig.additionalIncome.startDate ? Validators.required : ''],
					sourceName: [income.sourceName]
				}));
			});
		} else {
			incomeAdd.push(this.fb.group({
				sourceName: [''],
				incomeType: [null, Validators.required],
				amount: [0],
				annualAmount: ['', Validators.required],
				startDate: [''],
				startDateFull: ['', this.appConfig.additionalIncome.startDate ? Validators.required : ''],
				schedule: ['MONTHLY'],
			}));
		}
		this.incomeForm = this.fb.group({
			incomeAdd: this.fb.array(incomeAdd)
		});
	}

	addRemoveIncome(index: any, action: any) {
		const control = this.incomeForm.controls.incomeAdd as FormArray;
		const values: any = this.incomeForm.getRawValue();

		if (action === 'remove' && control.length > 0) {
			if (values.incomeAdd[index].hasOwnProperty('additionalIncomeId') && values.incomeAdd[index].additionalIncomeId) {
				this.removeIncome(values.incomeAdd[index].additionalIncomeId, control, index);
			} else {
				control.removeAt(index);
			}

		} else if (action === 'add') {
			control.push(this.fb.group({
				sourceName: [''],
				incomeType: [null, Validators.required],
				amount: [0],
				annualAmount: ['', Validators.required, Validators.min(100)],
				startDate: [''],
				startDateFull: ['', this.appConfig.additionalIncome.startDate ? Validators.required : ''],
				schedule: ['MONTHLY'],
			}));
		}
	}

	removeIncome(additionalIncomeId: any, control: any, index: any) {
		if (additionalIncomeId) {
			this.applicantService.deleteAdditionalIncome(this.applicant.applicantId, additionalIncomeId)
				.subscribe(res => {
					control.removeAt(index);
				});
		}
	}

	updateAmount(income: any) {
		const annualAmount = income.get('annualAmount').value;
		income.patchValue({amount: Number(annualAmount.toString().replace(/[^0-9]*/g, ''))});
	}

	getControls() {
		return (this.incomeForm.get('incomeAdd') as FormArray).controls;
	}

	removeAll() {
		this.dialogRef.close({
			data: {
				incomes: null,
				submit: true,
			}
		});
	}

	onSubmit() {
		const controls = this.incomeForm.controls;
		if (this.incomeForm.invalid) {
			Object.keys(controls).forEach((controlName: string) => {
				const control: any = controls[controlName];
				if (control instanceof FormArray) {
					const groupControls = control.controls;
					groupControls.forEach((cname: any) => {
						const gControls = cname.controls;
						Object.keys(gControls).forEach(name => {
							gControls[name].markAsTouched();
							if (name === 'annualAmount' && gControls[name].value < 100) {
								gControls[name].setErrors({'min-max': true});
							}
						});
					});
				}
				control.markAsTouched();
			});
			return;
		}
		this.dialogRef.close({
			data: {
				incomes: this.incomeForm.getRawValue().incomeAdd,
				submit: true,
			}
		});
	}

	changeStartDate(e, income) {
		const date = e.target.value
		if (date) {
			const d = moment(date).format('MM/DD/YYYY');
			income.patchValue({startDate: d});
		}

	}
}
