import {Inject, Injectable} from '@angular/core';
import {APP_ENV_CONFIG, APP_CONFIG} from "../index";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  static config: any = {};
  static env: any = {};

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    @Inject(APP_ENV_CONFIG) private environment: any
  ) {
  }

  init() {
    AppConfigService.config = this.appConfig
    AppConfigService.env = this.environment
  }

}
