export class AddressModel {
    addressId: number;
    applicantId: number;
    applicationId: number;
    streetAddress: string;
    fullAddress: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    addressUsage = 'PRIMARY'; // 0 = Home | 1 = Mailing |  2 = Temporary
    type: string; // 0 = Business | 1 = Residential
    county: string;
    country: string;
    current: boolean;
	addressData: any;
	mailingAddressData: any;

    constructor() {
        this.streetAddress = '';
        this.fullAddress = '';
        this.addressLine2 = '';
        this.city = '';
        this.state = '';
        this.zipCode = '';
        this.county = '';
        this.country = '';
        this.addressUsage = 'PRIMARY';
        this.current = true;
		this.addressData = {};
		this.mailingAddressData = {};
    }
}
