import { Directive, EventEmitter, HostListener, Inject, Input, OnDestroy, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as moment from 'moment';
import { KeyIdentifierModel } from '../models/keyIdentifier.model';
import { StateList } from '../enums/process-state.enum';
import { APP_CONFIG, SharedKeyDataService } from '@creditsnap/app-config';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[min-max]'
})
export class CustomMinMaxValidatorDirective implements OnDestroy{
	@Output('min-max') valid = new EventEmitter<void>(); // tslint:disable-line:no-output-rename
	@Input() minValue: number;
	@Input() maxValue: number;
	@Input() income: number;

	keyIdentifier: KeyIdentifierModel;
	subscription: Subscription;

	constructor(
		private elRef: NgControl, private sharedKeyDataService: SharedKeyDataService,
		@Inject(APP_CONFIG) private appConfig: any,
	) {
		this.subscription = sharedKeyDataService.keyIdentifier$.subscribe((data) => {
			this.keyIdentifier = data;
		});
	}

	@HostListener('blur')
	handleClick() {
		this.markFieldsAsDirty();
		this.emitIfValid();
	}

	private markFieldsAsDirty() {
		if(!this.elRef) {
			return;
		}
		const name: any = this.elRef.name;
		// console.log(this.elRef.hasError('required'));
		if (this.elRef.hasError('required') && !this.elRef.value && this.elRef.value !== 0) {
			this.elRef.control.setErrors({ 'min-max': true });
		} else if (name === 'salaryString') {
			let salaryMinValue = 0;
			let salaryMaxValue = 999999;

			if (this.keyIdentifier.loanPurposeConfig && this.keyIdentifier.loanPurposeConfig.minAnnualIncome) {
				salaryMinValue = this.keyIdentifier.loanPurposeConfig.minAnnualIncome;
			}

			if (this.keyIdentifier.loanPurposeConfig && this.keyIdentifier.loanPurposeConfig.maxAnnualIncome) {
				salaryMaxValue = this.keyIdentifier.loanPurposeConfig.maxAnnualIncome;
			}
			const value = (this.elRef.value || '0').replace(/[^0-9]*/g, '');
			if (Number(value) < salaryMinValue || Number(value) > salaryMaxValue) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'last4SSN') {
			if (this.elRef.value.toString().length !== 4 || isNaN(Number(this.elRef.value.toString()))) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'zipCode' && (this.elRef.value && (this.elRef.value.toString().length !== 5 || isNaN(Number(this.elRef.value.toString()))))) {
			this.elRef.control.setErrors({ 'min-max': true });
		} else if (name === 'dateOfBirth') {
			const dob = this.elRef.control.value;
			const minAge = (this.minValue || this.minValue == 0) ? this.minValue : 18;
			const today = moment().startOf('day');
			const delta = today.diff(dob, 'years', false);
			console.log(' DOBValidatorDirective => ', dob, today, delta);
			if (delta < minAge) {
				this.elRef.control.setErrors({ 'min': true });
			} else if (delta > 125) {
				this.elRef.control.setErrors({ 'max': true });
			}
		} else if (name.toLowerCase().includes('email')) {
			// tslint:disable-next-line:max-line-length
			const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(this.elRef.control.value) {
				if (!EMAIL_REGEX.test(this.elRef.control.value)) {
					this.elRef.control.setErrors({ 'email': true });
				}
			}
		} else if (name === 'payOffAmount') {
			if (this.elRef.value < 5000) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'mileage') {
			const maxAmount = this.maxValue;
			if(maxAmount && this.elRef.value) {
				if (Number(this.elRef.value.replace(/[^0-9]*/g, '')) > maxAmount) {
					this.elRef.control.setErrors({ 'min-max': true });
				}
			}
		} else if (name === 'income') {
			if(!this.elRef.value) {
				return;
			}

			if(!this.income) {
				this.income = Number(this.elRef.value.replace(/[^0-9]*/g, ''));
			} else {
				this.income = Number(this.income.toString().replace(/[^0-9]*/g, ''));
			}

			const minIncome = (this.minValue || this.minValue == 0) ? this.minValue : 100;
			const maxAmount = this.maxValue || 9999999;
			if (this.income < minIncome || this.income > maxAmount) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
			this.income = undefined; // to reset local variable
		} else if (name === 'residenceLengthMonth' || name === 'employeeLengthMonth') {
			if (Number(this.elRef.value) < 0) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
			if (Number(this.elRef.value) > 12) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'residenceLengthYear' || name === 'employeeLengthYear') {
			this.maxValue = this.maxValue || 50;
			if (Number(this.elRef.value) < 0) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
			if (Number(this.elRef.value) > this.maxValue) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'loanAmount') {
			let configMinValue = 5000;

			if (this.keyIdentifier.loanPurposeConfig && this.keyIdentifier.loanPurposeConfig.minLoanAmount) {
				configMinValue = this.keyIdentifier.loanPurposeConfig.minLoanAmount;
			} else if (this.appConfig && this.appConfig.minMax && this.appConfig.minMax.loanAmount && this.appConfig.minMax.loanAmount.min) {
				configMinValue = this.appConfig.minMax.loanAmount.min;
			}

			let configMaxValue = 9999999;

			if (this.keyIdentifier.loanPurposeConfig && this.keyIdentifier.loanPurposeConfig.maxLoanAmount) {
				configMaxValue = this.keyIdentifier.loanPurposeConfig.maxLoanAmount;
			} else if (this.appConfig && this.appConfig.minMax && this.appConfig.minMax.loanAmount && this.appConfig.minMax.loanAmount.max) {
				configMaxValue = this.appConfig.minMax.loanAmount.max;
			}

			//const minAmount = this.minValue || configMinValue;
			//const maxAmount = this.maxValue || configMaxValue;
			const value = (this.elRef.value || '0').replace(/[^0-9]*/g, '');
			if (Number(value) < configMinValue || Number(value) > configMaxValue) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name.indexOf('assetsLiabilitiesQue') > -1) {
			if (Number(this.elRef.value.replace(/[^0-9]*/g, '')) > 10000000) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'annualAmount') {
			const value = Number(this.elRef.value.toString().replace(/[^0-9]*/g, ''));
			if (Number(value) < this.minValue) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'addressState') {
			const state = this.elRef.value.toString();
			const allStatesCodes = StateList.map(item => item.code);

			if (state && allStatesCodes.indexOf(state.toUpperCase()) === -1) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		} else if (name === 'promoCode') {
			const regExPromo = /^[a-zA-Z0-9_]*$/;
			/*const code = this.promoCodes.find(t => t.toLowerCase() === this.elRef.value.toLowerCase());
			if (!code) {
				this.elRef.control.setErrors({'invalid-code': true});
				return;
			}*/
			if (!regExPromo.test(this.elRef.control.value)) {
				this.elRef.control.setErrors({ 'invalid-code': true });
			}
		} else if (name === 'bankRoutingNumber' || name === 'percentageOwnership'|| name === 'employeeCount') {
			const minAmount = this.minValue || 0;
			const maxAmount = this.maxValue || 99999999;
			const value = (this.elRef.value || '0').replace(/[^0-9]*/g, '');
			if (Number(value) < minAmount || Number(value) > maxAmount) {
				this.elRef.control.setErrors({ 'min-max': true });
			}
		}
	}

	private emitIfValid() {
		if (this.elRef.valid) {
			this.valid.emit();
		}
	}

	ngOnDestroy() {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
