export enum StipulationStatusEnum {
  Pending = 'PEND',
  Cleared = 'CLEARED',
  Override = 'OVERRIDE'
}

export enum StipulationSourceEnum {
  Underwriting = 'UNDERWRITING',
  Fraud = 'FRAUD',
  Manual = 'MANUAL'
}

export enum StipulationGroupNameEnum {
  'KYC' = 'KYC',
  'INCOME-VERIFICATION' = 'INCOME-VERIFICATION',
  'UPLOAD-DOCS' = 'UPLOAD-DOCS'
}
