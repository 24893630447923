import {Directive, ElementRef, HostListener} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
    selector: 'input[alphabetWithSpace]',
	providers: [{ provide: NG_VALIDATORS, useExisting: AlphabetWithSpaceDirective , multi: true}]
})
export class AlphabetWithSpaceDirective implements Validator {

    constructor(private _el: ElementRef) {
    }

	validate(control: AbstractControl): { [key: string]: any } {
		const isValid      = !control.value || (!control.value.startsWith(' ') && !control.value.endsWith(' '));
		return isValid ? null : { 'whitespace': true };
	}

    @HostListener('input', ['$event']) onInputChange(event: any) {
		const initalValue = this._el.nativeElement.value;
		if(this._el.nativeElement.value) {
			this._el.nativeElement.value = initalValue.trim();
		}
        this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z-_'. ]/g, '');

        if(initalValue.split(/\s+/).length > 2){
            this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z-_'. ]/g, '');
        }
        if (initalValue !== this._el.nativeElement.value) { // allow only one space
            event.stopPropagation();
        }
    }

	@HostListener('focusout') onFocsOut(event: any) {
		this._el.nativeElement.validate;
	}
}
