export class CCOfferVariationModel {
    offerVariationDetailsId: number;
    institution: string;
    lender: string;
    product: string;
    loanPurpose: string;
    purchaseAPR: string;
    balanceTransferAPR: string;
    cashAdvanceAPR: string;
    purchaseIntroAPR: string;
    balanceTransferIntroAPR: string;
    purchaseIntroPeriod: string;
    balanceTransferIntroPeriod: string;
    annualFee: string;
    cashBackRate: string;
    pointsPerDollar: string;
    balanceTransferFee: string;
    ratesDescription: string;
    latePaymentFee: string;
    latePaymentFeeDesc: string;
    gracePeriod: string;
    maxLoanAmount: string;
    penaltyAPRDesc: string;
    returnedPaymentFeeDesc: string;
    foreignTransactionDesc: string;

    constructor() {
        this.purchaseAPR = '';
        this.balanceTransferAPR = '';
        this.cashAdvanceAPR = '';
        this.purchaseIntroAPR = '';
        this.balanceTransferIntroAPR = '';
        this.purchaseIntroPeriod = '';
        this.balanceTransferIntroPeriod = '';
        this.annualFee = '';
        this.cashBackRate = '';
        this.pointsPerDollar = '';
        this.balanceTransferFee = '';
        this.ratesDescription = '';
        this.latePaymentFee = '';
        this.gracePeriod = '';
    }
}
