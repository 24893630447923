export enum ConsentEnum {
    ECONSENTANDSOFTINQUIRY= 'eConsentAndSoftInquiry',
	MARKETING= 'Marketing',
	SOFT_INQUIRY= 'SoftInQuiry',
	PromotionCode = 'PromotionCode',
	CID = 'CID',
	ReducedWorkHours = 'ReducedWorkHours',
	PaymentMethod = 'PaymentMethod',
	ReducedWorkHoursFullPay = 'ReducedWorkHoursFullPay',
	LoweredPayExplanation = 'LoweredPayExplanation',
	ReducedHoursReturn = 'ReducedHoursReturn',
	ReturnToWork = 'ReturnToWork',
	NotListedApplicantAdditionalIncome = 'NotListedApplicantAdditionalIncome',
	NotListedAdditionalIncomeDetails = 'NotListedAdditionalIncomeDetails',
	Marketing = 'Marketing',
	ReferralMethod = 'ReferralMethod',
	SoftInQuiry = 'SoftInQuiry',
	eConsentAndSoftInquiry = 'eConsent And SoftInquiry',
	eConsent = 'eConsent',
	HardInquiry = 'HardInquiry',
	ACHREPAYMENT = 'ACHREPAYMENT',
	EmailSubscribe = 'EmailSubscribe',
	SMSSubscribe = 'SMSSubscribe',
	BUSINESS = 'BUSINESS',
	DepositElectronicComm = 'DepositElectronicComm',
	DepositFinalConsent = 'DepositFinalConsent',
	BiometricConsent = 'BiometricConsent',
	VISA_DEBIT_CARD = 'VISA_DEBIT_CARD',
	COURTESY_PAY = 'COURTESY_PAY',
	V_PAY_CARD = 'V_PAY_CARD',
	ShareTransferOverDraftProtection = 'ShareTransferOverDraftProtection',
	eStatement = 'eStatement',
	DebtProtection = 'DebtProtection',
	GapProtection = 'GapProtection',
	TWInsurance = 'TWInsurance',
	MechanicalRepair = 'MechanicalRepair',
	CreditProtection = 'CreditProtection',
	JointConsent = 'JointConsent',
	FinalSubmitConsent = 'FinalSubmitConsent',
	BalanceTransferAmount = 'BalanceTransferAmount',
	BalanceTransfer = 'BalanceTransfer',
	MemberShipAccount = 'MemberShipAccount',
	CERTIFICATE_OPTION_ROLLOVER_NEW_RATE = 'CERTIFICATE_OPTION_ROLLOVER_NEW_RATE',
	CERTIFICATE_OPTION_TRANSFER_TO_SAVING_ACCOUNT = 'CERTIFICATE_OPTION_TRANSFER_TO_SAVING_ACCOUNT',
	DIVIDENDS_OPTION_CERTIFICATE = 'DIVIDENDS_OPTION_CERTIFICATE',
	DIVIDENDS_OPTION_SHARE_SAVING_ACCOUNT = 'DIVIDENDS_OPTION_SHARE_SAVING_ACCOUNT',
	DIVIDENDS_OPTION_SHARE_DRAFT_ACCOUNT = 'DIVIDENDS_OPTION_SHARE_DRAFT_ACCOUNT',
	BLANK = '',
	DIVIDENDS_OPTION = 'DIVIDENDS_OPTION',
	PHONEPIN = 'PHONEPIN'
}

export enum RadioTemplate {
	RadioCard = 'radio-card',
	RadioList = 'radio-list',
	RadioListTable = 'radio-list-table',
}

export enum Event {
	Success = 'Success',
	Error = 'Error',
	Warning = 'Warning'
}
