import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";

import {ProgressBarButtonComponent} from './components/progress-bar-button-component/progress-bar-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule],
  declarations: [
    ProgressBarButtonComponent
  ],
  exports: [ProgressBarButtonComponent]
})
export class ProgressBarButtonModule {
}
