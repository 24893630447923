import {LenderModel} from './lender.model';
import {CCOfferVariationModel} from './CCOfferVariation.model';
import {Options} from 'ng5-slider';

export class OfferVariationModel {
	applicationId: number;
	offerId: number;
	offerVariationId: number;
	offerCode: string;
	offerDate: Date;
	expireDate: Date;
	status: string;
	statusShortName: string;
	institution: string;
	lender: LenderModel;
	product: string;
	loanPurpose: string;
	relevancyScore: number;
	offerStatus: string;
	isLenderOfferAch: boolean;
	achDiscountRate: number;
	discountedPrice: number;
	valueType: string;
	defaultTerm: number;
	minTerm: number;
	maxTerm: number;
	apr: number;
	achApr: number;    // Used for the UI calculations
	maxAvailableCredit: number;
	maxLTVLoanAmount: number;
	minLTVLoanAmount: number;
	minAvailableCredit: number;
	customizedLoanAmount: number;
	maxLTVAvailableCredit: number;
	minLTVAvailableCredit: number;
	maxAvailableAmount: number; // for frontend use only
	minAvailableAmount: number; // for frontend use only
	withAllAPR: number;
	emi: number;
	maxDtiEmi: number;
	minDtiEmi: number;
	preDTI: number;
	postDTI: number;
	maxDTI: number;
	minDTI: number;
	salary: number;
	maxDTILoanAmount: number;
	optedAch: boolean;
	isVariationSelected: boolean;  // false - Not selected , true = Selected
	isOfferSelected: boolean;
	isLenderOfferOnlineRates: boolean = true;
	minLtv: number;
	maxLtv: number;
	collateralValue: number;
	backendLtvMax: number; // Use this if ancillary products are selected
	minLoanAmount: number;
	maxLoanAmount: number;
	maxLoanAmountFormatted: string;
	totalInterest: number;
	totalTax: number;
	totalAmount: number;
	isCalcSuccess: boolean = true;
	achMessage: string = '';
	offerCategory: string;
	offerCategoryCode: string;
	selectedPriorLTV: boolean;
	payOffAmount: number;
	downPayment: number;
	hasDownPaymentRequired: boolean;
	interestSaving: number;
	aprSaving: number;
	selectedApr: number;
	selectedEmi: number;
	selectedTerm: number;
	rateType: string;
	maxLoanAmountCalculationDone: boolean;
	offerVariationDetailsId: string;
	offerVariationDetails: CCOfferVariationModel;
	subProductName: string;
	purchaseAPR: string;
	balanceTransferAPR: string;
	cashAdvanceAPR: string;
	purchaseIntroAPR: string;
	balanceTransferIntroAPR: string;
	purchaseIntroPeriod: string;
	balanceTransferIntroPeriod: string;
	annualFee: string;
	cashBackRate: string;
	pointsPerDollar: string;
	balanceTransferFee: string;
	ratesDescription: string;
	latePaymentFee: string;
	gracePeriod: string;
	productCapacity: number;
	totalTaxesInFrontendLTV: number;
	fullyLoadedLoanAmount: number;
	maxBackendLTVLoanAmount: number;
	backendProductMax: number;
	addOnProducts: any;
	addOnProductEmi: number;
	totalProductCost: number;
	maxAvailableCreditFormatted: string;
	sliderOptions: Options;
	purchaseRateType: string;
	penaltyAPRDesc: string;
	returnedPaymentFeeDesc: string;
	imageUrl: string;

	constructor() {
		this.apr = 0;
		this.emi = 0;
		this.totalTax = 0;
		this.totalInterest = 0;
		this.isVariationSelected = false;
		this.selectedPriorLTV = false;
		this.maxDTILoanAmount = 0;
		this.lender = new LenderModel();
		this.maxLoanAmountCalculationDone = false;
		this.offerVariationDetails = new CCOfferVariationModel();
		this.addOnProducts = [];
		this.totalProductCost = 0;
	}
}
