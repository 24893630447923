<div class="progressbar pt-5" *ngIf="currentRoute !== 'myapp'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="position-relative my-4">
          <div class="progress">
            <div [style]="{height: progress + '%'}" *ngIf="appConfig.stepsLocation === 'side'" aria-valuemax="100"
                 aria-valuemin="0" aria-valuenow="0" class="progress-bar m-auto mt-3"
                 role="progressbar"></div>
            <div [style]="{width: progress + '%'}" *ngIf="appConfig.stepsLocation === 'top'" aria-valuemax="100"
                 aria-valuemin="0" aria-valuenow="0" class="progress-bar m-auto mt-3"
                 role="progressbar"></div>
          </div>
          <div class="progress-btn">
            <button [ngClass]="{'complete': this.routes['about'],'active': currentRoute === 'about'}"
                    class="complete btn btn-sm rounded-pill"
                    type="button">About You
            </button>
            <button [ngClass]="{'complete': this.routes['trade'],'active': currentRoute === 'trade'}"
                    class="btn active btn-sm rounded-pill"
                    type="button">Trade
            </button>
            <button [ngClass]="{'complete': this.routes['offer'],'active': currentRoute === 'offer'}"
                    class="btn btn-sm rounded-pill"
                    type="button">Offer
            </button>
            <!--            <button [ngClass]="{'complete': this.routes['add-on'],'active': currentRoute === 'add-on'}"-->
            <!--                    class="btn btn-sm rounded-pill"-->
            <!--                    type="button">add-on-->
            <!--            </button>-->
            <button [ngClass]="{'complete': this.routes['review'],'active': currentRoute === 'review'}"
                    class="btn btn-sm rounded-pill"
                    type="button"> Additional Info
            </button>
            <button [ngClass]="{'complete': this.routes['review'],'active': currentRoute === 'provision'}"
                    class="btn btn-sm rounded-pill"
                    type="button"> Provision
            </button>
            <button [ngClass]="{'complete': this.routes['thank-you'],'active': currentRoute === 'thank-you'}"
                    class="btn btn-sm rounded-pill"
                    type="button">Thank You
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
