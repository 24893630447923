import {Component, Input, EventEmitter, Output, HostListener} from '@angular/core';
import {ProgressButtonOptions} from "@creditsnap/data-models";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-bar-button',
  templateUrl: './progress-bar-button.component.html',
  styleUrls: ['./progress-bar-button.component.scss']
})
export class ProgressBarButtonComponent {
  _options: ProgressButtonOptions;
  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public get options(): ProgressButtonOptions {
    return this._options;
  }

  @Input() set options(newValue: ProgressButtonOptions) {
    this._options = newValue;
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (!this.options.disabled && !this.options.active) {
      this.btnClick.emit(event);
    }
  }
}
