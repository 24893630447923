<div class="m-mat-alert  m-mat-alert--{{type}} m-portlet m-portlet--rounded-force m-portlet--full-height" role="alert">
	<div class="m-portlet__head align-items-center">
		<h3 class="mb-0" mat-dialog-title>
			{{title}}
		</h3>
	</div>
	<form class="m-form m-form--group-seperator-dashed">
		<div mat-dialog-content class="m-portlet__body">
			<div class="m-form__group">
				<div class="row">
					<span class="col-12" [innerHTML]="message"></span>
				</div>

			</div>
		</div>
		<div mat-dialog-actions class="m-portlet__foot text-center" *ngIf="showCloseButton">
			<span (click)="closeAlert()" class="dialog-close">
            <mat-icon class="m--regular-font-size-lg5">close</mat-icon>
        </span>
		</div>
	</form>
</div>

