<mat-form-field class="mat-form-field-fluid">
    <mat-label>{{label}}</mat-label>
    <input matInput [matDatepicker]="picker"
           [(ngModel)]="dateOfBirth"
           [required]="!optional" [name]="name" [id]="id || name"
           #csDate="ngModel"
           [min]="minDate" [max]="maxDate"
           [disabled]="disabled"
           [readonly]="readonly"
           (dateChange)="onChangeDate($event)"
    >
    <mat-datepicker-toggle matIconSuffix [for]="picker" [id]="name" *ngIf="!readonly && !disabled"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="dateEleRef?.control.hasError('required') || dateEleRef?.control.hasError('matDatepickerParse')">{{label}} is <strong>required.</strong></mat-error>
    <mat-error *ngIf="dateEleRef?.control.hasError('matDatepickerMin')">{{label}} can not be less than {{minDate | date: 'MM/dd/YYYY'}}</mat-error>
    <mat-error *ngIf="dateEleRef?.control.hasError('matDatepickerMax')">{{label}} can not be greater than {{maxDate | date: 'MM/dd/YYYY'}}</mat-error>
</mat-form-field>
