import {Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly snackBar: MatSnackBar
    ) { }

    /**
     * Presents a toast displaying the message with a green background
     * @param message Message to display
     * @param duration
     * @example
     * this.notificationService.success("confirm oked");
     */
    success(message: string, duration: number = 3000) {
        this.openSnackBar(message, duration, 'success-snackbar');
    }

    /**
     * Presents a toast displaying the message with a red background
     * @param message Message to display
     * @param duration
     * @example
     * this.notificationService.error("confirm canceled");
     */
    error(message: string, duration: number = 3000) {
        this.openSnackBar(message, duration, 'error-snackbar');
    }

    /**
     * Presents a toast displaying the message with warn background
     * @param message Message to display
     * @param duration
     * @example
     * this.notificationService.warn("confirm canceled");
     */
    warn(message: string, duration: number = 3000) {
        this.openSnackBar(message, duration, 'warning-snackbar');
    }

    /**
     * Displays a toast with provided message
     * @param message Message to display
     * @param action Action text, e.g. Close, Done, etc
     * @param className Optional extra css class to apply
     * @param duration Optional number of SECONDS to display the notification for
     */
    openSnackBar(
        message: string,
        duration = 3000,
        className = '',
        action: string = ''
    ) {
        this.snackBar.open(message, action, {
            duration: duration,
            panelClass: [className],
            verticalPosition: 'top',
            horizontalPosition: 'center',
        });
    }
}
