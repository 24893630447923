<div class="mat-tel-input-container d-flex">
	<button
		type="button"
		mat-button
		[matMenuTriggerFor]="menu"
		class="country-selector"
		[disabled]="disabled"
	>
		<span class="country-selector-flag flag" [ngClass]="selectedCountry?.flagClass"></span>
		<span class="country-selector-code" *ngIf="selectedCountry?.dialCode">
			+{{ selectedCountry.dialCode }}
		</span>
	</button>
	<mat-menu #menu="matMenu" class="mat-tel-input-mat-menu-panel">
		<input
			*ngIf="enableSearch"
			class="country-search"
			[(ngModel)]="searchCriteria"
			type="text"
			[placeholder]="searchPlaceholder"
			(click)="$event.stopPropagation()"
		/>
		<ng-container *ngIf="!searchCriteria">
			<button
				type="button"
				mat-menu-item
				class="country-list-button"
				*ngFor="let country of preferredCountriesInDropDown"
				(click)="onCountrySelect(country, focusable)"
			>
				<div class="icon-wrapper">
					<div class="flag" [ngClass]="country.flagClass"></div>
				</div>
				<div class="label-wrapper">
					{{ country.name }}
					<span *ngIf="country?.dialCode">+{{ country.dialCode }}</span>
				</div>
			</button>
			<mat-divider *ngIf="preferredCountriesInDropDown?.length"></mat-divider>
		</ng-container>
		<ng-container *ngFor="let country of allCountries">
			<button
				type="button"
				mat-menu-item
				*ngIf="country | searchCountry: searchCriteria"
				class="country-list-button"
				(click)="onCountrySelect(country, focusable)"
			>
				<div class="icon-wrapper">
					<div class="flag" [ngClass]="country.flagClass"></div>
				</div>
				<div class="label-wrapper">
					{{ country.name }} +{{ country.dialCode }}
				</div>
			</button>
		</ng-container>
	</mat-menu>
	<input
		matInput
		type="tel"
		autocomplete="tel"
		maxlength="14"
		[ngClass]="cssClass"
		(blur)="onTouched()"
		(input)="onPhoneNumberAutoFill($event)"
		[(ngModel)]="phoneNumber"
		(ngModelChange)="onPhoneNumberChange()"
		[placeholder]="inputPlaceholder"
		[disabled]="disabled"
		[aria-describedby]="describedBy"
		#focusable
	/>
</div>
