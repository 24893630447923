import {AddressModel} from './address.model';


export class LenderModel {
  lenderId: number;
  name: string;
  contact: string;
  email: string;
  type: string;  // 0 - Bank, 1 - Credit Union, 2 - Community Bank, 3 - Market Place

  // The following fields will be empty unless we ask through additional service
  address: AddressModel;
  finalSubmitChannel: string;
  finalSubmitWebForwardLink: string;
  addOnProductAmountIntoFrontedLTV: number;
  minimumPaymentText: string;
  purchaseFixedBaseRate: string;
  purchaseVariableBaseRate: string;
}
