import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, APP_ENV_CONFIG, APP_TEXT_CONFIG} from '../index';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  defaultInterpolation: any;

  constructor(
    @Inject(APP_TEXT_CONFIG) public textConfig: any,
    @Inject(APP_CONFIG) public appConfig: any,
    @Inject(APP_ENV_CONFIG) public env: any,
  ) {
    this.defaultInterpolation = {
      phoneNumber: this.appConfig.phoneNumber,
      institutionId: this.env.institutionId,
      institutionName: this.appConfig.institutionName,
    };
  }

  instant(key, interpolateParams = {}) {
    interpolateParams = {
      ...this.defaultInterpolation,
      ...interpolateParams,
    }

    return this.interpolate(this.getParsedValue(this.textConfig, key), interpolateParams);
  }

  interpolate(expr, params) {
    let result;
    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr;
    }
    return result;
  }

  interpolateFunction(fn, params) {
    return fn(params);
  }

  interpolateString(expr, params) {
    if (!params) {
      return expr;
    }
    return expr.replace(this.templateMatcher, (substring, b) => {
      let r = this.getParsedValue(params, b);
      return this.isDefined(r) ? r : substring;
    });
  }

  getParsedValue(target, key) {
    let keys = typeof key === 'string' ? key.split('.') : [key];
    key = '';
    do {
      key += keys.shift();
      if (this.isDefined(target) && this.isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key];
        key = '';
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += '.';
      }
    } while (keys.length);
    return target;
  }

  isDefined(value) {
    return typeof value !== 'undefined' && value !== null;
  }
}
