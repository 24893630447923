import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'creditsnap-message-model',
  templateUrl: './message-model.component.html'
})
export class MessageModelComponent {

  type: 'primary | accent | warn';
  duration = 0;
  message = '';
  showCloseButton = true;

  constructor(public dialogRef: MatDialogRef<MessageModelComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.type = data.type;
    this.showCloseButton = data.showCloseButton;
    this.message = data.message;
  }

  closeAlert() {
    this.dialogRef.close();
  }
}
