<div class="m-portlet m-0 p-0">
  <div class="m-portlet__head">
    <div class="m-portlet__head-progress">
      <!-- here can place a progress bar-->
<!--      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>-->
    </div>
    <div class="m-portlet__head-caption" mat-dialog-title>
      <div class="m-portlet__head-title">
				<span class="m-portlet__head-icon m--hide">
					<i class="la la-gear"></i>
				</span>
        <h3 class="m-portlet__head-text">
          {{data.title}}
        </h3>
      </div>
    </div>
  </div>
  <div class="m-form">
    <div class="m-portlet__body" mat-dialog-content>
      <div class="form-group m-form__group row">
        <div class="col-lg-12" [innerHTML]="(viewLoading && data.waitDescription) ? data.waitDescription : data.description"></div>
      </div>
    </div>
	  <mat-dialog-actions>
    <div class="m-portlet__foot m-portlet__foot--fit text-right ">
      <div class="m-form__actions m-form__actions--sm">
        <div class="d-flex justify-content-between">
          <button *ngIf="data?.cancelText !== false" type="button" mat-button mat-raised-button (click)="onNoClick()">{{ data.cancelText || 'Cancel'}}</button>&nbsp;
          <button *ngIf="data?.okText !== false" type="button" mat-button mat-raised-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">{{ data.okText || 'Ok'}}
          </button>
        </div>
      </div>
    </div>
	  </mat-dialog-actions>
  </div>
</div>
