export const text: any = {
    aboutPage: {
        title: 'See your pre-qualified offer and rate in as little as 2 minutes.', // See your {{pre-qualified}} offers {{loanPurpose}} in as little as 2 minutes.
        subTitle: 'Checking your rate will not affect your credit score.',  // Completing the form below will not affect your credit score.
        loanPurposePlaceholder: 'What are you interested in?',
        cashApplicationTitle: 'Complete the form below for {{title}}',
        cashApplicationSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
        coBorrowerTitle: 'Tell me about your Co-Borrower.',
        coBorrowerSubTitle: 'With a Co-Borrower, you can rely on joint income to qualify for better credit options.',
        primaryBorrowerTitle: 'Complete the Application Below to Get Started with Purchasing Your Vehicle.',
        haveOfferCodeText: 'Received a mailer from us? Use RSVP Code.',
        housingCostHint: 'Monthly Mortgage or Monthly Rent Payment.',
        grossAnnualIncomeHint: 'Your before tax income can include bonuses, commissions and tips. Please do not include non-applicant income.',
        textBelowSubmitButton: 'Checking your rate will not affect your credit score.',
    },
    stepPage: {
        title: 'See your pre-qualified offer and rate in as little as 2 minutes.', // See your pre-qualified offers in as little as 2 minutes.
        subTitle: 'Checking your rate will not affect your credit score.',  // Completing the form below will not affect your credit score.
        next: 'Next',  // Next
        back: 'Back',  // Back
        reset: 'Reset',  // Reset
    },
    // radioButton: {
    //   yes: 'Yes',  // Back
    //   no: 'No',  // no
    // },
    tradePage: {
        title: 'You are almost there! We just need a few details.', // Last step before you can see your offers.
        vehicleInformation: 'Vehicle Information', // Vehicle Information
        identificationNumber: 'Do you have Vehicle Identification Number?', // Do you have Vehicle Identification Number?
        beforeStep: 'Last step before you can see your offers.?', // Last step before you can see your offers.
        loanBalance: 'Current Loan Balance', // Current Loan Balance
        paymentMonthly: 'Monthly Payment', // Monthly Payment
        outstandingBalance: 'Est. Outstanding Balance', // Est. Outstanding Balance
        Change: 'Change', // Change
        informationVehicle: 'Vehicle information', // Vehicle information
        selectedVehicle: 'Your Selected Vehicle', // Your Selected Vehicle
        Mileage: 'Mileage', // Mileage
        accessories: 'Please select any additional options you have on your vehicle.', // Please select vehicle accessories.
        noTradesScenarioTitle: 'We need additional information before you can see your offers.',
        loanTitle: 'Current Loan Balance',
        tradeLineErrorText: 'Please select a loan or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details',
        tradeLineDisplayTitle: 'Do you want to transfer balance from another {{tradeLineDisplay}} loan? Select Skip below if you are not transfering balance.',
        loanPurposeConfigHint: 'Please provide approximate outstanding loan amount. You may find on your current loan statement',
        loanPurposeConfigError: ' Please enter a valid Payoff amount value greater than {{loanAmount}}.',
        customMonthlyPayment: 'Please enter current monthly payment',
        noVehicleDisclosure: 'Choosing this option will give you preliminary loan rates. If you already have a vehicle in mind, enter that vehicle information below to receive more accurate rates.',
        noVehicleDisclosure_refi: 'Choosing this option will give you preliminary loan rates. If you are able to find vehicle information, enter vehicle information below to receive more accurate rates.',
        propertyAddressTitle: 'Update address if your property address is not the same as the address you provided',
		tradeLineTitle: 'Please select a trade-line or "I do not see my {{tradeLineDisplay}} loan in this list" option to enter the current loan details',
        tradeSelectionTextLong: {
			'R': 'Select the vehicle loan that you want to refinance',
            'R_PROMO': 'Select the vehicle loan that you want to refinance',
			'CC_CASH': 'Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card',
            'CC_REWARDS': 'Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card',
            'CC_CLASSIC': 'Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card',
            'CC_SPACEX': 'Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card',
            'CC_NSS': 'Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card',
		},
    },
    offerPages: {
        headlineTitle1: 'Congratulations, {{applicantFirstName}}! ', // Congratulations, Iram! Several
		headlineTitle2: 'Your pre-qualified offers are ready. ', // Congratulations, Iram! Several
        ccHeadlineTitle: 'Your {{pre-qualified}} card offers are ready. ',
        termBasedLoanSubTitle: 'Select or customize your offer to continue to the next step.',
        textAboveSliderInstalment: '',
		textAboveSliderRevolving: 'Select your desired line of credit amount',
        preQualified: 'These Pre-Qualified offers are based on the information you provided and information from your credit file. These are \n' +
            '                      initial, conditional offers that are subject to change, based on completion of a final credit application,\n' +
            '                      verification of the information you provided,your ability to repay, and other conditions. The\n' +
            '                      offers are non-binding, are not guaranteed and are not a commitment to lend. ',
        aprLegalNoteTermBasedLoan: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit rating, ' +
        'amount financed and other factors. Rates and terms are subject to change without prior notice; ' +
        'other restrictions may apply.',
        aprLegalNoteNotTermBasedLoan: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit rating, ' +
            'amount financed and other factors. Rates and terms are subject to change without prior notice; ' +
            'other restrictions may apply.',
        aprCCNoteContent: '',
        paymentExample: 'Payment Example: Monthly payments for a $10,000 loan at 5.74% APR with a term of 4 years would result in 48 monthly payments of $234.74.',
        achDiscountLabel: 'Do you want save by signing up for automatic payments?'
    },
    financePage: {
        pageTitle: 'Review and Submit. Final Submit Page',
		headline: 'Your application is almost ready for submission.',
		subHeadline: null,
		mobileHeadline: 'Your application is almost ready for submission.',
		pendHeadline: 'Based on the information provided, your application needs further review. Please provide additional details so we can expedite the review of your application.',
        employmentLengthHelpTextYear: 'Please enter years of employment.',
        employmentLengthHelpTextMonth: 'Please enter month of employment.',
        noCollateralScenarioTitle: 'The presented offer is without the vehicle value. Please add the collateral information to get the final offer.',
        noOfferStatusTitle: 'No offers available for your selections. Please change your loan amount and/or other selections to see available offers',
        downPayment: '(Estimated. Final down payment amount may vary)',
        applicationCheckboxTitle: 'By clicking the Submit button below, all borrowers consent, acknowledge, and agree to the following:',
		taxIDDisplayText: 'Social Security Number',
		taxIDErrorText: 'SSN is required',
		finalSubmitLoaderMessage: '' //Your application is in transit to {{lenderName}}.
    },
    headerMenu: {
        title: ''
    },
    footerMenu: {
        title: ''
    },
    MESSAGES: {
        OFFERS: {
            LENDER_APPROVED_NO_RATES: {
                TITLE: 'Thank you for completing the application!',
                MESSAGE: 'Thank you for submitting your application. We need additional information before ' +
                    'you can see your offer. Please contact your NASA FCU ' +
                    'Member Services Representative at 1-888-627-2328 so that we can get you the loan that meets your needs.'
            },
            NO_BUREAU_REPORT: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            NO_BUREAU_HIT_COBORROWER: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            NO_OFFER: {
                TITLE: '',
                MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
            },
            PEND: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            CROSS_SELL_DISCLOSURE: {
                MESSAGE: ''
            },
            NO_OFFER_NO_HIT: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            NO_OFFER_HARD_DECLINE: {
                TITLE: '',
                MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
            },
            NO_OFFER_FOUND: {
                TITLE: '',
                MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
            },
            NO_OFFER_SOFT_DECLINE: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            NO_OFFER_PRE_FAIL: {
                TITLE: '',
                MESSAGE: 'We\'re unable to find offers based on the information provided. If you would like to provide additional details and submit an application we can review your request.'
            },
            NO_OFFER_WITH_LTV: {
                TITLE: '',
                MESSAGE: 'We appreciate your inquiry, but we do not have any potential offers to present at this time.'
            },
            NO_OPTIONS: {
                MESSAGE: 'Thank you for submitting your request. At this time, we are unable to find a suitable offer for you.'
            },
            PAYMENT_EXAMPLE: {
                MESSAGE: 'Payment Example: Monthly payments for a $10,000 loan at 3.19% APR with a term of 4 ' +
                    'years would result in 48 monthly payments of $222.18.'
            },
            APR_LEGAL_NOTE: {
                MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit ' +
                    'worthiness, amount financed and other factors.  Rates and terms are subject to change without prior ' +
                    'notice. Other restrictions apply.'
            },
            APR_LEGAL_NOTE_Non_Auto: {
                MESSAGE: 'APR = Annual Percentage Rate. The specific rate and term will be dependent upon your credit worthiness, ' +
                    'amount financed and other factors. Rates and terms are subject to change without prior notice. ' +
                    'Other restrictions may apply.'
            },
            PRE_QUALIFIED_OFFER: {
                MESSAGE: 'The Pre-Qualified offers presented above are based on the information you provided and information from ' +
                    'your credit file. These are initial, conditional offers that are subject to change, based on the completion of a ' +
                    'final credit application, verification of the information you provided, your ability to repay, and other conditions. ' +
                    'The offers are non-binding, are not guaranteed, and are not a commitment to lend'
            },
        },
        CASH_APPLICATION: {
            TITLE: 'Thank you for completing the application!',
            MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our Representatives will be contacting' +
                ' you in the next 1-2  business days 24 hours with the next steps. ' +
                'You can reach us at {{phone}} if you have any questions in the meantime.'
        },
        FINANCIAL_SUBMITTED: {
            TITLE: 'Thank you for completing the application!',
            MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
                'contacting you in the next 1-2 business days with the next steps. ' +
                'You can reach us at {{phone}} if you have any questions in the meantime.'
        },
        PROVISIONING_COMPLETE: {
            TITLE: 'You are all set.',
            MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
                'contacting you in the next 1-2 business days with the next steps. ' +
                'You can reach us at {{phone}} if you have any questions in the meantime.'
        },
        FINANCIAL_SUBMITTED_FAILED: {
            TITLE: 'Thank you for completing the application!',
            MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
                'contacting you in the next 1-2 business days with the next steps. ' +
                'You can reach us at {{phone}} if you have any questions in the meantime.'
        },
        EXCEPTION: {
            TITLE: 'An error has occurred.',
            MESSAGE: 'The system is unable to process your application at this time. We apologize for any inconvenience. Please try ' +
                'again later or contact a Loan Specialist at 1-888-NASA-FCU (627-2328).'
        },
        DISCLOSURE: {
            FINAL: {
                CONSENT_1: 'You are providing written consent under the Fair Credit Reporting Act to authorize NASA FCU to',
                CONSENT_2: 'obtain your credit report with a hard credit inquiry ',
                CONSENT_3: ' from credit reporting agencies. You acknowledge that this is an application for credit and authorize ' +
                    'NASA FCU to obtain such information solely to evaluate your credit application. ' +
                    'By clicking this box, you consent, acknowledge, and agree that NASA FCU can process your final application for credit.' +
                    'You are also consenting to allow us offer you savings and benefits on other products.'
            },
            SOFT_INQUIRY: {
                CONSENT_1: ' You are providing express written consent under the Fair Credit Reporting Act to authorize ' +
                    'NASA Federal Credit Union to obtain your credit profile from credit reporting agencies. You authorize ' +
                    'NASA Federal Credit Union to obtain such information solely to evaluate your eligibility for certain loan product ' +
                    'pre-qualification offers and other cross-sell offers and acknowledge that this is not an application for credit.',
                CONSENT_UPTO_LENDER: '',
                CONSENT_PRIVACY: 'You agree that you received NASA FCU <a ' +
                    'href="" target="_new">Privacy Policy</a>. ' +
                    'You also verified that you can read and print or keep these electronic documents and you ' +
                    'certify that your selections and information in this form are true, correct and complete.',
                CONSENT_MARKETING: 'I hereby consent to receive email, SMS or other marketing communications from NASA FCU.' +
                    'I further expressly agree that NASA FCU may contact me at the calling numbers providing including ' +
                    'my cellular number, via alive, auto dialer, prerecorded telephone call, text chat or email message. ' +
                    'I further understand that my telephone company may impose charges on me for these contacts and I am not ' +
                    'required to provide this consent to be effective even if my calling numbers or email address are included on ' +
                    'ado-not-call list or registry. I understand that my consent to receive calls is not required in order to ' +
                    'purchase any goods or services. If you do not want to receive any marketing communications just un-check the box.'
            },
            MARKETING_INQUIRY: {
                CONSENT_MARKETING_1: 'I hereby consent to receive email, SMS or other marketing communications from NASA FCU. ' +
                    'I further expressly agree that NASA FCU ',
                CONSENT_MARKETING_2: ' may contact me at the calling numbers providing including my cellular number, via live, ' +
                    'auto dialer, prerecorded telephone call, text, chat or email message. I further understand that my telephone ' +
                    'company may impose charges on me for these contacts and I am not required to provide this consent to be effective even if ' +
                    'my calling numbers or email address are included on a do-not-call list or registry. I understand that my ' +
                    'consent to receive calls is not required in order to purchase any goods or services. ' +
                    'If you do not want to receive any marketing communications just un-check the box'
            }
        },
		PROVISIONS: {
			THANKYOU: {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
			'THANKYOU-APPROVED': {
				TITLE: 'You are all set.',
				MESSAGE: 'We really appreciate your business and opportunity to serve you. One of our expert Member Service Representatives will be ' +
					'contacting you in the next 1-2 business days with the next steps. ' +
					'You can reach us at {{phone}} if you have any questions in the meantime.'
			},
		}
    }
};


