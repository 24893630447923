import {CollateralPropertyModel} from './collateral-property.model';
import {TaxModel} from './tax.model';
import {TradelineModel} from './tradeline.model';

export class CollateralModel {
  collateralId: number;
  applicationId: number;
  tradeLineId: number; // this is not mandatory
  vehicleCategory: string;
  vehicleType: string; //  Auto, Motorcycle
  valuationType: string; // 0 - Manual, 1 - Automated
  // Retail, MSRP, Trade-in --- this is not updated by backend, may be forced update from UI
  valueType: string;
  year: string;
  make: string;
  model: string;
  series: string;
  body: string;
  color: string;
  stateOfRegistration: string;
  ziCodeOfRegistration: string;
  vin: string;
  uid: string;
  makeuid: string;
  modeluid: string;
  seriesuid: string;
  mileage: number;
  licensePlate: string;
  autoType: string; // 0 - New , 1 - Used
  imageURL: string;

  msrp: number;
  mileageAdj: number;
  retailValue: number;
  retailValueWithAccessories: number;
  retailPlusVin: number;
  retailPlusVinMileage: number;
  tradeIn: number;
  tradeInWithAccessories: number;
  tradeInPlusVin: number;
  tradeInPlusVinMileage: number;
  type: string;
  maxLoanAmount: number;
  loanAmount: number;
  monthlyPayment: number;
  currentLoanAPR: number;
  description: string;
  vendor: string; // Vehicle Valuation Service provider
  status: string;
  isValuationCompleted = false;
  properties: CollateralPropertyModel[];
  purchaseType: string;
  purchasePrice: number;
  hasCollateralProperties: boolean;
  isVINCheckBoxChecked: boolean;
  totalTax: number;
  loanAmountTax: number;
  taxes: TaxModel[];
  tradeLineVOS: TradelineModel[];
  bankName: string;
  loanAccountNumber: string;

  othersOnTitle: boolean;
  otherPersonOnTitleName: string;
  otherPersonOnTitleEmail: string;
  otherPersonOnTitlePhone: string;

  constructor() {
    this.loanAmount = 0;
    this.loanAmountTax = 0;
    this.totalTax = 0;
    this.monthlyPayment = 0;
    this.tradeLineVOS = [];
	this.type = null;
  }
}
