<h2 class="mb-0" mat-dialog-title>
	Additional Monthly Income Information
	<!-- for {{applicant.firstName | titlecase}} {{applicant.lastName | titlecase}}-->
</h2>
<form [formGroup]="incomeForm" class="m-form m-form--group-seperator-dashed">
	<mat-dialog-content>
		<h6>{{ appTextConfig.aboutPage.additionalIncomeHint || '' }}</h6>
		<div class="m-portlet__body">

			<div formArrayName="incomeAdd">
				<div class="m-form__group">
					<ng-container
						*ngFor="let res of getControls(); let i = index">

						<div [formGroupName]="i"
							 class="row align-items-center">
							<div class="col mat-form-border">
								<div class="mar-form-space">
									<mat-form-field class="mat-form-field-fluid">
										<mat-label>Income Type</mat-label>
										<mat-select formControlName="incomeType" name="incomeType"
													[required]="true">
											<ng-container *ngFor="let type of incomeTypeList">
												<mat-option [value]="type.id" id="{{type.text}}">{{ type.text }}
												</mat-option>
											</ng-container>
										</mat-select>
										<mat-error>Income type is <strong>required.</strong></mat-error>
									</mat-form-field>
								</div>

							</div>
							<div class="col mat-form-border">
								<div class="mat-form-space">
									<mat-form-field class="mat-form-field-fluid">
										<mat-label> Monthly Income</mat-label>
										<input (change)="updateAmount(res)" currency-format
											   formControlName="annualAmount"
											   input-num
											   matInput min-max [minValue]="1" name="annualAmount" required type="text" />
										<span matPrefix>$&nbsp;</span>
										<span matSuffix>.00</span>
										<mat-error *ngIf="res.get('annualAmount').errors?.required">Income amount is
											<strong>required.</strong></mat-error>
										<mat-error *ngIf="res.get('annualAmount').hasError('min-max')">Income amount
											should be <strong>minimum $100.</strong></mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="col mat-form-border" *ngIf="appConfig.additionalIncome.startDate">
								<div class="mat-form-space">
                  <creditsnap-date [label]="'Start Date (mm/dd/yyyy)'"
                                   formControlName="startDateFull"></creditsnap-date>
								</div>
							</div>
							<div class="col-sm-1 text-right mb-2">
								<a (click)="addRemoveIncome(i, 'remove')" *ngIf="this.getControls().length > 1"
								   class="btn btn-danger btn-sm" id="removeIncomeIcon"
								   href="javascript:" matTooltip="Remove income">
									<i class="fa fa-minus"></i>
								</a>
							</div>
						</div>

					</ng-container>
					<a (click)="addRemoveIncome(0, 'add')"
					   class="btn btn-primary btn-sm float-right" id="addNewIncomeIcon"
					   href="javascript:;" matTooltip="Add new income">
						<i class="fa fa-plus"></i>
					</a>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<div class="justify-content-between mt-3 d-flex">
			<div>
				<button (click)="dialogRef.close()" class="me-2" name="cancelAdditionalIncome"
						id="cancelAdditionalIncome" color="primary" mat-stroked-button type="button">
					Cancel
				</button>
				<button (click)="removeAll()" class="ms-3" color="primary" name="removeAllAdditionalIncomes"
						id="removeAdditionalAllIncomes" mat-stroked-button type="button">Remove all
				</button>
			</div>
			<mat-bar-button (click)="onSubmit()" name="saveAdditionalIncomeDetails" id="saveAdditionalIncomeDetails"
							[options]="barButtonOptions" class="ms-3"></mat-bar-button>
		</div>
	</mat-dialog-actions>
</form>
