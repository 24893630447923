import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'creditsnap-m-alert',
	templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {

	type: string; // 'primary | accent | warn';
	duration = 0;
	message = '';
	title = '';
	showCloseButton = true;

	constructor(
		public dialogRef: MatDialogRef<AlertComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.type = data.type;
		this.duration = data.duration || 0;
		this.title = data.title;
		this.message = data.message;
		this.showCloseButton = data.showCloseButton;
	}

	ngOnInit() {
		/*if (this.duration === 0) {
			return;
		}

		setTimeout(() => {
			this.closeAlert();
		}, this.duration);*/
	}

	closeAlert() {
		this.dialogRef.close();
	}
}
