<button
  mat-button
  [type]="options.type"
  [color]="options.buttonColor"
  [class.active]="options.active"
  [class.mat-mdc-raised-button]="options.raised"
  [class.mat-mdc-outlined-button]="options.stroked"
  [class.mat-mdc-unelevated-button]="options.flat"
  [class.fullWidth]="options.fullWidth"
  [ngClass]="options.customClass"
  [disabled]="options.active || options.disabled"
>
  <ng-container *ngIf="options.buttonIcon">
    <mat-icon
      [class.is-mat-icon]="!options.buttonIcon.fontSet"
      [ngClass]="options.buttonIcon.customClass"
      [fontSet]="options.buttonIcon.fontSet"
      [fontIcon]="options.buttonIcon.fontIcon"
      [color]="options.buttonIcon.color"
      [svgIcon]="options.buttonIcon.svgIcon"
      [inline]="options.buttonIcon.inline"
    >
      {{ options.buttonIcon.fontSet ? '' : options.buttonIcon.fontIcon }}
    </mat-icon>
  </ng-container>

  <span>{{ options.text }}</span>

  <mat-progress-bar
    class="bar"
    *ngIf="options.active && !options.disabled"
    [color]="options.barColor"
    [mode]="options.mode"
    [value]="options.value"
  >
  </mat-progress-bar>
</button>
