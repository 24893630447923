import { RadioTemplate } from '@creditsnap/data-models';
import { BooleanInput } from '@angular/cdk/coercion';

export class RadioOptionModel {
    groupName: string;
    items: RadioListModel[];
    subProductTemplate?: RadioTemplate = RadioTemplate.RadioCard;

	constructor() {
		this.groupName = 'radioList';
		this.items = [];
	}
}

export class RadioListModel {
    id: string;
    name: string;
    value: string;
    heading: string;
    shortDescription?: string;
    extraInfo?: string;
    bonusInfo?: string;
    learnMore?: string;
    description?: string;
    image?: string;
	icon?: string;
    fee?: string;
    learnMoreActive?: boolean;
    isSearchData?: boolean;
    disabled?: BooleanInput = false;
    minBalance?: string;
    apy?: string;
	hasDate?: boolean;
	derosDate?: any;
    search?: {
        label: string,
        value: any,
        searchValues: Array<any>,
        loading: boolean
    } = {
		label: '',
		value: null,
		searchValues: [],
		loading: false
	};
}
