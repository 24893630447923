<h3 class="mb-0 text-center lh-sm"
    mat-dialog-title>
  {{appConfig.existingMemberFields.existingMemberText || 'Please provide the information below so we can verify your membership'}}
</h3>

<form (ngSubmit)="f.form.valid" #f="ngForm" class="m-form" novalidate>

  <mat-dialog-content class="mat-typography">
    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.firstName">
      <div class="mat-from-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>First Name</mat-label>
            <input matInput name="firstName" alphabetOnly
                   autocomplete="off" [(ngModel)]="existingMemberFields.firstName" #firstName="ngModel"
                   required>
            <mat-error *ngIf="firstName.hasError('required')">First Name is <strong>required.</strong></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.lastName">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>Last Name</mat-label>
            <input matInput name="lastName" placeholder="Last Name" alphabetWithSpace
                   autocomplete="off" [(ngModel)]="existingMemberFields.lastName" #lastName="ngModel"
                   required>
            <mat-error *ngIf="lastName.hasError('required')">Last Name is <strong>required.</strong></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.email">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>Email</mat-label>
            <input matInput name="email" min-max
                   autocomplete="off" [(ngModel)]="existingMemberFields.email" #email="ngModel"
                   required>
            <mat-error *ngIf="email.hasError('required')">Email is <strong>required.</strong></mat-error>
            <mat-error *ngIf="email.hasError('email')">Email is not <strong>valid.</strong></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.dateOfBirth">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <creditsnap-date name="dateOfBirthMember" [label]="'Date of birth (mm/dd/yyyy)'"
                           [maxDate]="maxDate" [minDate]="minDate"
                           [(ngModel)]="existingMemberFields.dateOfBirth"></creditsnap-date>
        </div>
      </div>
    </div>
    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.zipCode">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>Zip Code</mat-label>
            <input matInput type="tel" name="zipCode" min-max
                   [(ngModel)]="existingMemberFields.zipCode" #zipCode="ngModel" required />
            <mat-error>Zip must be 5 digits</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.last4SSN">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>Last 4-digits of SSN</mat-label>
            <input matInput [type]="'tel'" name="last4SSN"
                   #last4ssn="ngModel" min-max numbersOnly [(ngModel)]="existingMemberFields.last4SSN"
                   maxlength="4" minlength="4" required>
            <mat-error *ngIf="last4ssn.hasError('min-max')">Please enter last 4-digits of
              your SSN
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.ssn">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>SSN Number</mat-label>
            <input matInput [type]="hideSSN ? 'password' : 'tel'" name="ssn" ssn-mask maxlength="11"
                   autocomplete="off" [(ngModel)]="existingMemberFields.ssn" #ssn="ngModel"
                   required>
            <mat-icon matSuffix class="eye-icon" name="visibilityIcon" id="visibilityIcon"
                      (click)="hideSSN = !hideSSN">{{hideSSN ? 'visibility_off' : 'visibility'}}
            </mat-icon>
            <mat-error *ngIf="ssn.hasError('required')">SSN is <strong>required.</strong>
            </mat-error>
            <mat-error *ngIf="ssn.hasError('min-max')">Enter valid SSN number.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="m-form__group" *ngIf="appConfig.existingMemberFields.memberNumber">
      <div class="mat-form-border">
        <div class="mat-form-space">
          <mat-form-field class="mat-form-field-fluid w-100">
            <mat-label>Member Account Number</mat-label>
            <input matInput name="memberNumber"
                   autocomplete="off" [(ngModel)]="existingMemberFields.memberNumber" #memberNumber="ngModel"
                   required>
            <mat-error *ngIf="memberNumber.hasError('required')">Member Account Number is <strong>required.</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="p-4 pt-0">
    <div class="m-cs-subheader" *ngIf="errorMessage">
      <h5 class="m--font-danger">{{errorMessage}}</h5>
    </div>
    <div class="d-flex justify-content-between w-100">
      <button type="button" mat-stroked-button color="primary" name="cancelMembership" id="cancelMembership"
              (click)="dialogRef.close()">Cancel
      </button>
      <mat-bar-button [options]="barButtonOptions" (tsSubmitIfValid)="onSubmit()" name="continueMembership"
                      id="continueMembership"
                      matTooltip="No impact to your credit score, continue to check your loan options">
      </mat-bar-button>
    </div>
  </mat-dialog-actions>
</form>
