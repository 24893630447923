import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {SharedModule} from '@creditsnap/shared';
import {RouterLink} from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedModule, RouterLink],
	declarations: [
		HeaderComponent,
		FooterComponent,
		StepperComponent
	],
	exports: [HeaderComponent, FooterComponent, StepperComponent]
})
export class LayoutModule {
}
