import {CountryCode} from 'libphonenumber-js';
import {PhoneNumberFormat} from './intl-tel-input/intl-tel-input.component';
import {AppConfigService} from '@creditsnap/app-config';


export class PhoneNumberOptionModel {
    value?: string;
    preferredCountries?: Array<CountryCode>;
    enablePlaceholder?: boolean;
    inputPlaceholder?: string;
    numberFormat?: PhoneNumberFormat;
    cssClass: string;
    onlyCountries?: Array<CountryCode>;
    enableAutoCountrySelect?: boolean;
    searchCountryFlag?: boolean;
    searchCountryField?: Array<string>;
    searchCountryPlaceholder: string;
    maxLength?: number;
    selectFirstCountry?: boolean;
    selectedCountryISO?: CountryCode;
    required?: boolean;
    inputId?: string;
    separateDialCode?: boolean;

    constructor() {
        this.enableAutoCountrySelect = true;
        this.enablePlaceholder = true;
        this.searchCountryFlag = true;
        this.separateDialCode = false;
        this.searchCountryField = ['all'];
        this.preferredCountries = AppConfigService.config.preferredCountries || ['US']; // Most used countries to list at the top of the selection for ease of selection
        this.numberFormat = 'national';
        this.selectFirstCountry = true;
        this.selectedCountryISO = 'US';
        this.maxLength = 14;
        this.required = true;
        this.inputId = 'cs-phone-number';
        this.inputPlaceholder = 'Enter your phone number';
        this.onlyCountries = AppConfigService.config.supportedCountries || ['US']; // Not available as an option unless in this list
    }
}
