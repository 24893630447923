import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Ng5SliderModule} from 'ng5-slider';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module} from 'ng-recaptcha';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, UpperCasePipe} from '@angular/common';
import {MaterialModule} from '@creditsnap/material';
import {DataModelsModule, ReplaceStringPipe} from '@creditsnap/data-models';
import {ProductSelectionComponent} from './components/product-selection/product-selection.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {ExistingMemberComponent} from './_components/existing-member/existing-member.component';
import {PurposeCodeModalComponent} from './_components/purpose-code-modal/purpose-code-modal.component';
import {AskSsnComponent} from './_components/ask-ssn/ask-ssn.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {SharedComponent} from './shared.component';
import {AdditionalIncomeComponent} from './_components/additional-income/additional-income.component';
import {LoaderComponent} from './_components/loader/loader.component';
import {ProgressBarButtonModule} from '@creditsnap/progress-button';
import {ProcessModalComponent} from './_components/process-modal/process-modal.component';
import {DatePickerModule} from './adapter/DatePickerModule';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OtpModelComponent} from './_components/otp-model/otp-model.component';
import {NgxOtpInputModule} from 'ngx-otp-input';
import {MatTabsModule} from '@angular/material/tabs';
import {AlertComponent} from './_components/alert/alert.component';
import {ConfirmDialogComponent} from './_components/confirm-dialog/confirm-dialog.component';
import {MessageModelComponent} from './_components/message-model/message-model.component';
import {NotificationService} from './services/notification/notification.service';
import {AddressComponent} from './components/address/address.component';
import {EditAddressComponent} from './components/address/edit-address/edit-address.component';
import {TypingLoaderComponent} from './_components/typing-loader/typing-loader.component';
import {RadioGroupComponent} from './components/radio-group/radio-group.component';
import {OtpComponent} from './_components/otp/otp.component';
import {YearMonthFormFieldComponent} from './custom-form-fields/year-month/year-month-form-field.component';
import {PhoneInputComponent} from './components/phone-input/phone-input.component';
import {DateComponent} from './components/date/date.component';
import {IntlTelInputComponent} from './components/phone-input/intl-tel-input/intl-tel-input.component';

const cc = window as any;
cc.cookieconsent.initialise({
	cookie: {
		domain: window.location.hostname,
	},
	position: 'bottom',
	theme: 'classic',
	// palette: {
	// 	popup: {
	// 		background: "#00163a",
	// 		text: "#ffffff",
	// 		link: "#ffffff"
	// 	},
	// 	button: {
	// 		background: "#7700FF",
	// 		text: "#ffffff",
	// 		border: "transparent"
	// 	}
	// },
	type: 'info',
	content: {
		message:
			'We are using cookies to ensure you get the best experience on our website.',
		dismiss: 'Got it!',
		deny: 'Decline',
		link: 'Learn more',
		href: 'https://creditsnap-public.s3.amazonaws.com/html/ABC/cookie+policy.html',
		policy: 'Cookie Policy',
		header: 'Cookies used on the website!',
		allow: 'Allow cookies',
	},
});

@NgModule({
	entryComponents: [
		ExistingMemberComponent,
		PurposeCodeModalComponent,
		EditAddressComponent,
		AskSsnComponent,
		OtpModelComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MaterialModule,
		ReactiveFormsModule,
		DataModelsModule,
		RouterModule,
		Ng5SliderModule,
		RecaptchaV3Module,
		ProgressBarButtonModule,
		DatePickerModule,
		MatTooltipModule,
		NgxOtpInputModule,
		MatTabsModule,
	],
	exports: [
		LoaderComponent,
		ProductSelectionComponent,
		OtpComponent,
		ProgressBarComponent,
		MatTooltipModule,
		Ng5SliderModule,
		MatTabsModule,
		TypingLoaderComponent,
		RadioGroupComponent,
		OtpModelComponent,
		DataModelsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		ProgressBarButtonModule,
		AddressComponent,
		EditAddressComponent,
		AskSsnComponent,
		YearMonthFormFieldComponent,
		PhoneInputComponent,
		DateComponent
	],
	declarations: [
		SharedComponent,
		AdditionalIncomeComponent,
		ProductSelectionComponent,
		ProgressBarComponent,
		ExistingMemberComponent,
		PurposeCodeModalComponent,
		AskSsnComponent,
		RedirectComponent,
		OtpComponent,
		MessageModelComponent,
		LoaderComponent,
		ProcessModalComponent,
		TypingLoaderComponent,
		RadioGroupComponent,
		YearMonthFormFieldComponent,
		OtpModelComponent,
		AlertComponent,
		ConfirmDialogComponent,
		AddressComponent,
		EditAddressComponent,
		PhoneInputComponent,
		DateComponent,
		IntlTelInputComponent
	],
	providers: [
		DecimalPipe,
		CurrencyPipe,
		DatePipe,
		UpperCasePipe,
		NotificationService,
		ReplaceStringPipe,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: () => {
				return {
					siteKey: '6LfI-SUbAAAAAAuWGZ8BNLvT2a2Ewi-OoSBbTJhS',
				} as RecaptchaSettings;
			},
		},
	],
})
export class SharedModule {
	static environment: any;
}
