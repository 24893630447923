import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {Router, RouterModule, UrlSerializer} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
	APP_CONFIG,
	APP_ENV_CONFIG,
	APP_TEXT_CONFIG,
	AppConfigService,
	commonConfig,
	commonTextConfig,
	HttpAuthInterceptService,
	LowerCaseUrlSerializer,
	mergeDeepFn
} from '@creditsnap/app-config';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {config} from '../config/custom.config';
import {text} from '../config/text.config';
import {LayoutModule} from '@creditsnap/layout';
import * as Sentry from '@sentry/angular';


@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(
			[
				{
					path: '',
					loadChildren: () =>
						import('@creditsnap/routing-flow').then((module) => {
							module.Flow1Module.environment = environment;
							return module.Flow1Module;
						}),
				},
			],
			{ initialNavigation: 'enabledBlocking' }
		),
		HttpClientModule,
		LayoutModule,
	],
	providers: [
		{ provide: APP_ENV_CONFIG, useValue: environment },
		{
			provide: APP_CONFIG,
			useValue: {
				...commonConfig,
				...config,
			},
		},
		{
			provide: APP_TEXT_CONFIG,
			useValue: mergeDeepFn(commonTextConfig, text),
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpAuthInterceptService,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (config: AppConfigService) => {
				return () => config.init();
			},
			multi: true,
			deps: [AppConfigService],
		},
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},

	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(trace: Sentry.TraceService) {}
}
