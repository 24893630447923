import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NgForm} from '@angular/forms';

@Directive({
  selector: '[tsSubmitIfValid]'
})
export class SubmitIfValidDirective {
  @Output('tsSubmitIfValid') valid = new EventEmitter<void>(); // tslint:disable-line:no-output-rename
  @Input() options: any;

  constructor(
    private formRef: NgForm,
    private el: ElementRef) {
  }

  @HostListener('click')
  handleClick() {
    this.markFieldsAsDirty();
    this.emitIfValid();
  }

  private markFieldsAsDirty() {
    Object.keys(this.formRef.controls)
      .forEach(fieldName => {
        this.formRef.controls[fieldName].markAsDirty();
        this.formRef.controls[fieldName].markAsTouched();
      });
  }

  private emitIfValid() {
    if (this.formRef.valid) {
      if (this.options) {
        if (!this.options.disabled && !this.options.active) {
          this.valid.emit();
        }
      } else {
        this.valid.emit();
      }
    }
  }
}
