import {OfferVariationModel} from './offerVariation.model';
import {LoanAmountVariationsModel} from "./loan-amount.model";


export class OfferRateTypeTermsModel {
    fixed: number[];
    variable: number[];
    constructor() {
        this.fixed = [];
        this.variable = [];
    }
}


export class OfferRateTypeModel {
    fixed: OfferVariationModel[];
    variable: OfferVariationModel[];
    constructor() {
        this.fixed = [];
        this.variable = [];
    }
}

export class OfferOptionsVO {
    applicationId: number;
    offerId: number;
    offerVariationId: number;
    offerCategoryCode: string;  // P - Lowest Payment, I - Lowest interest, A - Lowest APR
    customizedLoanAmount: number;
    tempLoanAmount: number;
    monthlyPayment: number;
    selectedTerm: number;
    selectedRateType: string;
    optedAch: boolean;
    apr: number;
    withAllAPR: number;
    expireDate: Date;
    status: string;
    statusShortName: string;
    relevancyScore: number;  // Generated based on the customer profile, Lender approval rate
    isLenderOfferAch: boolean;
    isOfferSelected: boolean;
    selectedVariation: OfferVariationModel;
    loanAmountVariations: LoanAmountVariationsModel;
    hasQualifiedRates: boolean;
    loanAmtOptions: any = {};
    terms: OfferRateTypeTermsModel | any;
    variations: OfferVariationModel[];
    recommendations: OfferRateTypeModel | any;
    maxLoanAmountForAllVariations: number;
    minLoanAmountForAllVariations: number;

    constructor() {
      //  this.terms = [];
        this.variations = [];
        this.selectedVariation = new OfferVariationModel();
        this.loanAmountVariations = new LoanAmountVariationsModel();
        this.loanAmtOptions = {};
        this.recommendations = new OfferRateTypeModel();
        this.terms = new OfferRateTypeTermsModel();
        this.isOfferSelected = false;
        this.maxLoanAmountForAllVariations = 0;
        this.minLoanAmountForAllVariations = 999999;
    }
}
