import {Directive, EventEmitter, HostListener, Inject, Output, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {APP_ENV_CONFIG} from "@creditsnap/app-config";

@Directive({
	selector: '[ssn-mask]',
})
export class SsnMaskDirective {

	@Output('ssn-mask') valid = new EventEmitter<void>(); // tslint:disable-line:no-output-rename
	@Input() prePopulatedSSN = false;

	constructor(public elRef: NgControl,
				@Inject(APP_ENV_CONFIG) private environment) {
	}

	@HostListener('ngModelChange', ['$event'])
	onModelChange(event) {
		this.onInputChange(event, false);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		this.onInputChange(event.target.value, true);
	}

	@HostListener('blur')
    @HostListener('focusout')
    @HostListener('keyup')
	handleClick() {
		this.markFieldsAsDirty();
		this.emitIfValid();
	}

	onInputChange(event, backspace) {
		let newVal = event.toString().replace(/\D/g, '');
		if (backspace && newVal.length <= 6) {
			newVal = newVal.substring(0, newVal.length - 1);
		}
		if (newVal.length === 0) {
			newVal = '';
		} else if (newVal.length <= 3) {
			newVal = newVal.replace(/^(\d{0,3})/, '$1');
		} else if (newVal.length <= 6) {
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2');
		} else if (newVal.length <= 9) {
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
		} else {
			newVal = newVal.substring(0, 9);
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
		}
		// console.log('directive => ', newVal);
		this.elRef.valueAccessor.writeValue(newVal);
	}

	private markFieldsAsDirty() {
		if(this.prePopulatedSSN || !this.elRef.value) {
			return;
		}
		if (!this.elRef.value) {
			this.elRef.control.setErrors({'min-max': true});
			return;
		}

		const ssn = this.elRef.value.toString().replace(/([\D,\s,.€()_-])+/g, '');
		// if (!(ssn.length === 9 || ssn.length === 10)) {
		if (ssn.length !== 9) {
			this.elRef.control.setErrors({'min-max': true});
		}
		// If SSN's length more than 5 and if same digit is repeated more than 5 times than SSN is not valid
		if (ssn.length > 5 && this.checkRepeatedNumber(ssn) > 6) {
			this.elRef.control.setErrors({'min-max': true});
			return;
		}
		if (ssn.length === 9) {
			let [firstSet, secondSet, thirdSet] = this.elRef.value.toString().split('-');
			if (!thirdSet) {
				firstSet = ssn.substring(0, 3);
				secondSet = ssn.substring(3, 5);
				thirdSet = ssn.substring(5, 9);
			}
			const checkNumber = ['000', ...(this.environment.type !== 'TEST' ? ['666'] : [])];
			//if (checkNumber.includes(firstSet) || (Number(firstSet) >= 900 && Number(firstSet) <= 999))
			// ITINs are 9 series. So removing the 900 to 999 check to support ITIN CSHELP-1337
			if (checkNumber.includes(firstSet)) {
				this.elRef.control.setErrors({'min-max': true});
			}
			if (secondSet === '00') {
				this.elRef.control.setErrors({'min-max': true});
			}
			if (thirdSet === '0000') {
				this.elRef.control.setErrors({'min-max': true});
			}
		}
	}

	private emitIfValid() {
		if (this.elRef.valid) {
			this.valid.emit();
		}
	}


	private checkRepeatedNumber(str) {
		const strAr = String(str).split('');
		const mostFrequentNumber = strAr.sort((a, b) =>
			strAr.filter(v => v === a).length
			- strAr.filter(v => v === b).length
		)[strAr.length - 1];
		return strAr.filter(x => x === mostFrequentNumber).length;
	}
}
