import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ApplicantModel} from '@creditsnap/data-models';
import * as moment from 'moment';
import {APP_CONFIG, ApplicationService} from "@creditsnap/app-config";

@Component({
  selector: 'creditsnap-existing-member',
  templateUrl: './existing-member.component.html',
  // styleUrls: ['./existing-member.component.scss']
})
export class ExistingMemberComponent implements OnInit {
  barButtonOptions: any = {
    active: false,
    text: 'Continue',
    buttonColor: 'primary',
    barColor: '#C1D72E',
    raised: true,
    stroked: false,
    mode: 'indeterminate',
    value: 0,
    disabled: false
  };
  applicant: ApplicantModel;
  errorMessage: string = null;
  isExisting: any = null;
  existingMemberFields: any = {
    lastName: '',
    dob: null,
    dateOfBirth: null,
    zipCode: null,
    last4SSN: null,
    memberNumber: null
  };
  minDate = new Date();
  maxDate = new Date();
  hideSSN = true;

  constructor(
    public dialogRef: MatDialogRef<ExistingMemberComponent>,
    private applicationService: ApplicationService,
    @Inject(APP_CONFIG) public appConfig: any,
  ) {
    this.dialogRef.disableClose = true;
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.minDate.setFullYear(this.minDate.getFullYear() - 100);
  }

  ngOnInit() {
  }

  check() {
    if (this.isExisting === 'no') {
      this.dialogRef.close();
    }
  }

  onSubmit() {
    this.barButtonOptions.active = true;
    if (!this.appConfig.existingMemberFields.dateOfBirth) {
      delete this.existingMemberFields.dateOfBirth;
      delete this.existingMemberFields.dob;
    }
    if (!this.appConfig.existingMemberFields.firstName) {
      delete this.existingMemberFields.firstName;
    }
    if (!this.appConfig.existingMemberFields.lastName) {
      delete this.existingMemberFields.lastName;
    }
    if (!this.appConfig.existingMemberFields.email) {
      delete this.existingMemberFields.email;
    }
    if (!this.appConfig.existingMemberFields.zipCode) {
      delete this.existingMemberFields.zipCode;
    }
    if (!this.appConfig.existingMemberFields.ssn) {
      delete this.existingMemberFields.ssn;
    }
    if (!this.appConfig.existingMemberFields.last4SSN) {
      delete this.existingMemberFields.last4SSN;
    }
    if (!this.appConfig.existingMemberFields.memberNumber) {
      delete this.existingMemberFields.memberNumber;
    }

    if (this.existingMemberFields.ssn) {
      this.existingMemberFields.ssn = this.existingMemberFields.ssn.toString().replace(/([\s,.€()_-])+/g, '');
    }
    const data = {
      ...this.existingMemberFields,
      ...(this.appConfig.existingMemberFields.dateOfBirth ? {
        dob: moment(this.existingMemberFields.dateOfBirth).format('MM/DD/YYYY').toString()
      } : {}),
    };

    this.applicationService.getExistingMemberDetails(data).subscribe({
		next: (resp) => {
			this.dialogRef.close({...resp, ...{userProvidedDetail: this.existingMemberFields}});
		}, error: (e) => {
			this.dialogRef.close({
				...this.existingMemberFields,
				...(this.appConfig.existingMemberFields.dateOfBirth ? {
					dob: moment(this.existingMemberFields.dateOfBirth).format('MM/DD/YYYY').toString()
				} : {}),
				...(this.appConfig.existingMemberFields.zipCode ? {
					zip: this.existingMemberFields.zipCode
				} : {}),
				...(this.appConfig.existingMemberFields.last4SSN ? {
					ssn: this.existingMemberFields.last4SSN
				} : {}),
				...(this.appConfig.existingMemberFields.ssn ? {
					ssn: this.existingMemberFields.ssn
				} : {}),
				...(this.appConfig.existingMemberFields.memberNumber ? {
					memberNumber: this.existingMemberFields.memberNumber
				} : {}),
				...{userProvidedDetail: this.existingMemberFields}
			});
		}, complete: () => {

		}
	})
  }
}
