export class EmploymentModel {
    applicationId: number;
    applicantId: number;
    employmentId: number;
    empName: string;
    empStatus: string; // Current or Previous
    empType: string; // employed, contracted,
    position: string;
    salary: number;
    salaryString: string;
    schedule: string; // Annnually, Monthly
    length: number; // Length of Employement
    years: number;
    months: number;
	workingHoursPerWeek: number;
	workPhone: string;
	empPhone: any;
    empStreetAddress1: any;
    empStreetAddress2: any;
	empCity: any;
	empState: any;
    empCountry: any;
	empZip: any;

    constructor() {
        this.empStatus = 'C';
        this.schedule = 'MONTHLY';
        this.salary = 0;
    }
}
