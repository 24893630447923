// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	isMockEnabled: false,  // You have to switch this, when your real back-end is done
	domain: 'https://applycs.nasafcu.com',
	baseUrl: 'https://applycs.nasafcu.com',
	apiUrl: 'https://prod-api-elb.creditsnap.com/csb2b',
	websiteUrl: 'https://nasafcu.com',
	channel: 'web',
	provisionApiUrl: 'https://xo7mcoan2f.execute-api.us-east-1.amazonaws.com/cs/provisions',
	institutionId: 'NASA',
	institutionConfig: {
		minLoanAmount: 5000
	},
	s3Images: 'https://creditsnapimages.s3.amazonaws.com/', // 'https://s3.amazonaws.com/creditsnapimages/',
	client: {
		clientAppStatusURL: '',
		secretKey: '',
	},
	auth: {
		basic: 'Basic Y3NfdHJ1c3RlZF9jbGllbnRfcHJvZDpqM3JYSkE2UEx5SkVNSm1N',
		scope: 'read write trust',
		grantType: 'password',
		interceptorSkipHeader: 'X-Skip-Interceptor'
	},
	debug: false,
	type: 'PROD',
	google_siteKey: '6LfI-SUbAAAAAAuWGZ8BNLvT2a2Ewi-OoSBbTJhS',
	incomeVerificationLink: 'https://api.measureone.com/v3/js/m1-link-2021042000.js',
	socureSDKKey: 'cc59c506-66e3-4984-93ce-1842b55d02f8',
	socketBaseUrl: 'wss://m2pbbyg907.execute-api.us-east-1.amazonaws.com/production',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
