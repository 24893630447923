import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderSubject: BehaviorSubject<any>;
  public loader$: Observable<any>;

  constructor() {
    this.loaderSubject = new BehaviorSubject<any>({show: false, message: ''});
    this.loader$ = this.loaderSubject.asObservable();
  }

  show(message: any = '') {
    this.loaderSubject.next({show: true, message});
  }

  hide() {
    this.loaderSubject.next({show: false, message: ''});
  }
}
