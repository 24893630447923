import { ProgressButtonOptions } from '@creditsnap/data-models';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ProgressButtonIcon } from '../interfaces/progress-button.interface';

export class ProgressButtonModel implements ProgressButtonOptions {
	active: boolean;
	barColor: any;
	buttonColor: any;
	buttonIcon: ProgressButtonIcon;
	customClass: string;
	disabled: boolean;
	fab: boolean;
	flat: boolean;
	fullWidth: boolean;
	icon: ProgressButtonIcon;
	id: string;
	mode: ProgressSpinnerMode;
	raised: boolean;
	spinnerColor: any;
	spinnerSize: number;
	spinnerText: string;
	stroked: boolean;
	text: string;
	type: string;
	value: number;

	constructor(buttonOptions = {}) {
		this.active = false;
		this.text = 'Continue';
		this.buttonColor = 'primary';
		this.barColor = '#C1D72E';
		this.raised = true;
		this.stroked = false;
		this.mode = 'indeterminate';
		this.value = 0;
		this.disabled = false;

		Object.keys(buttonOptions).forEach(key => {
			if (buttonOptions[key] !== undefined) {
				this[key] = buttonOptions[key];
			}
		})
	}

}
