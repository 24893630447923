import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CSPostResponseModel} from '@creditsnap/data-models';
import {APP_ENV_CONFIG} from '../index';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  API_OFFER_URL = '';

  constructor(private http: HttpClient, @Inject(APP_ENV_CONFIG) private environment: any) {
    this.API_OFFER_URL = this.environment.apiUrl + '/offer'
  }

  generateOfferForApplication(_applicationId: number): Observable<CSPostResponseModel> {
    return this.http.put<CSPostResponseModel>(this.API_OFFER_URL + `/generate/${_applicationId}`, {});
  }

  orderCreditBureauReport(_applicationId: number, _applicantId: number = 0, appModified: boolean = false, data = {}): Observable<CSPostResponseModel> {
    let url = `${this.environment.apiUrl}/application/bureau/${_applicationId}`;
    if (appModified && _applicantId) {
      url += `/applicant/${_applicantId}/appModified/${appModified}`;
    }
    return this.http.put<CSPostResponseModel>(url, data);
  }

  findOffersByApplicationId(_applicationId: number): Observable<any> {
    console.log(' offer findOfferByApplicationId => ', _applicationId);
    return this.http.get<any>(this.API_OFFER_URL + `/${_applicationId}`);
  }

  // UPDATE => PUT: update the selected offer options
  updateSelectedOffer(_applicationId: number, offer: any): Observable<any> {
    //  console.log('update offer =>', JSON.stringify(offer));
    return this.http.put<CSPostResponseModel>(this.API_OFFER_URL + `/${_applicationId}`, offer);
  }

  getLenderInfo(lenderName: string): Observable<any> {
    const url = `${this.environment.apiUrl}/lender/${lenderName}/${this.environment.institutionId}`;
    return this.http.get(url);
  }

  getCreditCardRatesLookup(): Observable<any> {
    const url = `${this.environment.apiUrl}/rates/lookup/offerdetails/${this.environment.institutionId}`;
    return this.http.get(url);
  }

  getCrossSellOffers(_applicationId: number): Observable<any> {
    return this.http.get<any>(this.environment.apiUrl + `/offer/crosssell/${_applicationId}`);
  }

  getCreditCardTermsConditions(fileName: string) : Observable<any> {

    const headers = new HttpHeaders({
      'X-Skip-Auth': 'Yes',
      'Accept': 'text/plain, */*',
    });
    return this.http.get<any>(this.environment.s3Images + `html/${this.environment.institutionId}/${fileName.toLowerCase()}`,
        {headers, responseType: 'text' as 'json'});
  }

  // /offer/{applicationId} - PUT, loanamout, offerId, variationId, ach, apr, emi, term
  // These are updated with offer entity, offer status = ACCEPTED
  //  term, offerVariationId, offerId, apr, emi, ach, discountedPrice, discountRate, loanAmount
}
